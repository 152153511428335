import React, { useState, useEffect } from 'react';

import allSkills from "../json/allSkillsv4.json"

const SkillImage = ({ skCode, btnId, handlePlus, ap, theme }) => {
  const [skImg, setSkImg] = useState(0);


  const subImgContainer = {
    position: 'relative',
    width: '36px',
    height: '36px'
  }

  const skillImgStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }

  // useEffect with prop dependency
  useEffect(() => {
    const filteredSk = allSkills.filter(sk => sk.code === skCode)

    setSkImg(filteredSk[0])
  }, [skCode]);


  return (
    <div style={subImgContainer}>
      <img style={skillImgStyle} src={`images/skills01/${skImg.ic}.png`} alt="" />
      {(ap === 1 ? <img style={skillImgStyle} src={`skillBG/sk-overlay-p2.png`} alt="" /> : (
        skImg.slotIndex === 1 ?
          <img style={skillImgStyle} src={`skillBG/sk-overlay-1.png`} alt="" />
          :
          <img style={skillImgStyle} src={`skillBG/sk-overlay-6.png`} alt="" />

      ))}

    </div>
  );
};

export default SkillImage;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { Container, Box, CssBaseline, Grid, Stack, IconButton, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import HomeIcon from '@mui/icons-material/Home';

import dragonaBanner from './assets/banner.png';
import dragonaCursor from './assets/dragonaCur.png';
import dragonaHandCursor from './assets/dragonaHandCursor.png';
import dragonaSwordCursor from './assets/dragonaSwordCursor.png';
import dragonaSCursor from './assets/dragonaSCursor.png';
import dragonaGold from './assets/gold.png';
import HomePage from './HomePage'; // Import HomePage component
import Footer from './Footer'; // Import MUI sample Footer component
import EquipmentPage from './EquipmentPage.js'; // Import HomePage component
import ItemPage from './ItemPage.js'; // Import HomePage component
import MobPage from './MobPage.js';
import SkillPage from './SkillPage.js';
import QuestPage from './QuestPage.js';

const App = () => {
  // Define the initial theme mode state (light mode by default)
  const [themeMode, setThemeMode] = useState('dark');

  // Function to toggle between light and dark themes
  const toggleThemeMode = (optional) => {

    if (optional === 'dark') {
      setThemeMode('dark');
    } else {
      if (window.location.pathname === '/skills') {
        setThemeMode('dark');
      } else {
        setThemeMode(themeMode === 'light' ? 'dark' : 'light');
      }
    }

  };


  // Create light and dark themes
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      background: {
        default: 'rgba(233,233,233,0.9)',
        paper: 'rgba(233,233,233,0.9)',
        dragonabg: 'rgba(25,118,210,0.85)',
      },
      table: {
        normal: 'rgba(220, 210, 210, .9)',
        headerbg: 'rgba(25,118,210,0.85)',
      },
      text: {
        primary: 'rgba(6,6,6,0.9)',
        secondary: 'rgba(6,6,6,0.9)',
      },
      rarity: {
        magic: '#090',
        rare: '#5B7FE5',
        unique: '#FF6829',
        legend: '#BC3A2D',
        other: 'rgb(129, 212, 250)',
        green: 'rgb(197, 225, 165)',
      },
      tooltip: {
        bg: 'rgba(10,10,10,0.8)',
        icon: 'rgba(10,10,10,0.35)',
        text: 'rgba(190, 170, 120, .85)',
        row: 'rgba(10,10,10,.98)',
        atk: 'rgba(10,10,10,.98)',
        label: 'rgba(190, 190, 190, .9)',
        default: 'rgba(220, 210, 210, .9)',
        divider: 'rgba(190, 176, 145, .4)',
      }
    },
    typography: {
      fontFamily: 'Ubuntu, Roboto, sans-serif', // Change the font family here
      fontSize: 12, // Change the base font size (optional)
      fontWeightRegular: 400, // Change the regular font weight (optional)
      fontWeightBold: 500, // Change the bold font weight (optional)
    },
    root: {
      cursor: `url(${dragonaCursor}), auto`, // Set custom cursor .cur file
      handCursor: `url(${dragonaHandCursor}), auto`, // Set custom cursor .cur file
      swordCursor: `url(${dragonaSwordCursor}), auto`, // Set custom cursor .cur file
      sCursor: `url(${dragonaSCursor}), auto`, // Set custom cursor .cur file
    },

  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        paper: 'rgba(6,6,6,0.75)',
        dragonabg: 'rgba(2,2,2,0.82)',
      },
      table: {
        normal: 'rgba(220, 210, 210, .9)',
        headerbg: 'rgba(30,30,30,0.8)',
      },
      text: {
        primary: 'rgba(220, 210, 210, .9)',
        secondary: 'rgba(220, 210, 210, .9)',
      },
      rarity: {
        magic: '#1c1',
        rare: '#5B7FE5',
        unique: '#FF6829',
        legend: '#BC3A2D',
        other: 'rgb(129, 212, 250)',
        green: 'rgb(197, 225, 165)',
      },
      tooltip: {
        bg: 'rgba(20,20,20,0.84)',
        icon: 'rgba(50,50,50,0.3)',
        text: 'rgba(190, 170, 120, .85)',
        row: 'rgb(190, 176, 145)',
        label: 'rgba(190, 190, 190, .9)',
        atk: 'rgba(230, 230, 230, .9)',
        default: 'rgba(220, 210, 210, .9)',
        divider: 'rgba(190, 176, 145, .3)',
      }
    },
    typography: {
      fontFamily: 'Ubuntu, Roboto, sans-serif', // Change the font family here
      fontSize: 12, // Change the base font size (optional)
      fontWeightRegular: 400, // Change the regular font weight (optional)
      fontWeightBold: 800, // Change the bold font weight (optional)
    },
    root: {
      cursor: `url(${dragonaCursor}), auto`, // Set custom cursor .cur file
      handCursor: `url(${dragonaHandCursor}), auto`, // Set custom cursor .cur file
      swordCursor: `url(${dragonaSwordCursor}), auto`, // Set custom cursor .cur file
      sCursor: `url(${dragonaSCursor}), auto`, // Set custom cursor .cur file
    },

    overrides: {
      // Override CSS styles here
      MuiMenuItem: {
        paper: {
          '& .MuiTablePagination-menuItem': {
            color: '#fff', // For example, change text color to red
            // Add more custom styles as needed
          },
        },
        root: {
          '& .MuiTablePagination-menuItem': {
            color: '#fff', // For example, change text color to red
            // Add more custom styles as needed
          },
        },
        default: {
          '& .MuiTablePagination-menuItem': {
            color: '#fff', // For example, change text color to red
            // Add more custom styles as needed
          },
        },
      },
    }



  });

  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    // Show the Snackbar on component mount (page load)
    setOpenSnackbar(true);
  }, []); // Empty dependency array to run only once on mount

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };



  return (
    <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
      <Box sx={{ cursor: `url(${dragonaCursor}), auto`, p: 0, m: 0, }}>
        <AppContent toggleThemeMode={toggleThemeMode} theme={themeMode === 'light' ? lightTheme : darkTheme} />
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="warning"
        >
          <Container>
            <span>Page is in the EARLY development phase! Data are based on client v1.0.20.499&nbsp;&nbsp;<span style={{ color: 'rgb(213, 100, 2)', textSize: '14px' }}>4</span> </span>
            <Box sx={{ position: 'relative', display: 'inline', top: '4px' }}><img src={dragonaGold} alt='G' /></Box>
          </Container>
        </MuiAlert>
      </Snackbar>
    </ThemeProvider>
  );
};

const AppContent = ({ toggleThemeMode, theme }) => {

  const unselectable = {
    WebkitUserSelect: 'none', /* For Safari */
    MozUserSelect: 'none', /* For Firefox */
    msUserSelect: 'none', /* For Microsoft Edge and Internet Explorer */
    userSelect: 'none' /* Standard syntax */
  }

  return (
    <>
      <Container sx={{ ...unselectable, minHeight: '87vh', cursor: theme.root.cursor, maxWidth: { xs: 'auto', sm: 'auto', md: '1000px', lg: '1300px' } }}>
        <Router >
          <CssBaseline /> {/* apply theme to the whole background */}

          <Grid container direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ pt: '5px' }}
          >

            <Grid item xs={12} sx={{
              maxWidth: { xs: '100vw', sm: '100vw', md: '700px', lg: '700px' },
              maxHeight: '50px',
            }}>
              <img src={dragonaBanner} alt="Banner" style={{ width: '100%', height: 'auto', maxHeight: '80px', zIndex: 980 }} />

            </Grid>
          </Grid>

          <Stack
            direction="row"
            justifyContent="right"
            spacing={0}
            useFlexGap
          >
            <Link style={{ cursor: theme.root.cursor, }} to="/">
              <IconButton sx={{ cursor: theme.root.cursor, fontSize: '10px' }}>
                <HomeIcon />
              </IconButton>
            </Link>
            <IconButton sx={{ cursor: theme.root.cursor, fontSize: '10px' }} onClick={toggleThemeMode} >
              {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Stack>



          <Routes>
            <Route path="/" element={<HomePage theme={theme} />} />
            <Route path="/equipment" element={<EquipmentPage theme={theme} />} />
            <Route path="/items" element={<ItemPage theme={theme} />} />
            <Route path="/monsters" element={<MobPage theme={theme} />} />
            <Route path="/skills" element={<SkillPage theme={theme} changeTheme={toggleThemeMode} />} />
            <Route path="/quests" element={<QuestPage theme={theme} />} />
          </Routes>
        </Router>
      </Container>
      <Footer theme={theme} />
    </>
  );
};

export default App;
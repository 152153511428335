import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/GitHub';
import DiscordIcon from './components/DiscordIcon';

function Copyright({theme}) {

  const unselectable = {
    WebkitUserSelect: 'none', /* For Safari */
    MozUserSelect: 'none', /* For Firefox */
    msUserSelect: 'none', /* For Microsoft Edge and Internet Explorer */
    userSelect: 'none' /* Standard syntax */
  }


  return (
    <Typography sx={unselectable} variant="body2" color="text.secondary" mt={1}>
      {'© '}
      <Link sx={{textDecoration: 'none', cursor: theme.root.swordCursor}} href="https://InsideEzulan.online/">Inside Ezulan</Link>&nbsp;|&nbsp;
      {new Date().getFullYear()}&nbsp;| Dragona Classic Database | Fanpage
    </Typography>
  );
}

const Footer = ({ theme }) => {
  return (
      <Container  
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 1, sm: 3 },
          py: { xs: 2, sm: 4 },
          px: {xs: 1, sm: 3},
          textAlign: { sm: 'center', md: 'left' },
          marginTop: 'auto', /* Push the footer to the bottom */
          cursor: theme.root.cursor,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 1, sm: 2 },
            width: '100%',
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
          <div>
            <Copyright theme={theme}/>
          </div>
          <Stack 
            direction="row"
            justifyContent="left"
            spacing={0}
            useFlexGap
            sx={{
              color: 'text.secondary',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="GitHub"
              sx={{ alignSelf: 'center',
              cursor: theme.root.swordCursor, }}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="LinkedIn"
              sx={{ alignSelf: 'center',
              cursor: theme.root.swordCursor, }}
            >
              <DiscordIcon />
            </IconButton>
          </Stack>
        </Box>
      </Container>
  );
}

export default Footer;
import React, { useState, useEffect } from 'react';
import { Container, Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import DataTable from './components/DataTable';
import EmptyTable from './components/EmptyTable';


import jsonEquip from './json/dragona_main_db.equips.json';

const EquipmentPage = ({ theme }) => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [activeTab1, setActiveTab1] = useState(0);
  const [activeTab2, setActiveTab2] = useState(0);
  const [activeTab3, setActiveTab3] = useState(0);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [isTabDisabled, setIsTabDisabled] = useState(false);


  const AntTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
      backgroundColor: 'rgba(24,144,255,0.4)',
      cursor: theme.root.cursor,
    },
  });



  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
    cursor: theme.root.cursor,
  }));

  const handleTabChange1 = (event, newValue) => {
    setActiveTab1(newValue);
    setPage(0);
    let at3 = 0

    if (newValue === 8 || newValue === 6) {
      if (isTabDisabled !== true) {
        setActiveTab3(0);
        setIsTabDisabled(!isTabDisabled);
        at3 = 1;
      }
    } else {
      if (isTabDisabled === true) {
        setIsTabDisabled(!isTabDisabled);
      }
    }

    const itemType = {
      0: 0,   // all
      1: 106, // weapon
      2: 105, // armor
      3: 108, // trousers
      4: 109, // shoes
      5: 103, // helm
      6: 111, // accessories    111 - 112 - 113
      7: 124, // transformation 119 ~ 125
      8: 145, // others         140 ~ 145
    }

    const job = {
      0: 0,      // 0 && 9 for all
      1: 101,    // Gunner
      2: 102,    // Snipe
      3: 103,    // Ranger
      4: 104,    // Magician
      5: 105,    // Guardian
      6: 106,    // Assassin
      7: 107,    // Shaman
      8: 108,    // Scout
    };

    // Tab1 = Item Type    || 0='' | 1=weapon | 2=Armor  | 3=Trousr | 4=Shoes    | 5=Helm  | 6=Accessories | 7=Transform | 8=Others
    // Tab2 = Rarity       || 0="" | 1=Normal | 2=Magic  | 3=Rare   | 4=Unique 
    // Tab3 = JobClass     || 0="" | 1=Gunner | 2=Sniper | 3=Ranger | 4=Magician | 5=Guardian | 6=Assassin | 7=Shaman | 8=Scout
    // dValue[0] = ItemType
    // dValue[1] = Rarity
    // dValue[2] = JobClass

    // load all unfiltereditems
    let scoutItems = items

    if (newValue > 0 && newValue < 6) {
      scoutItems = scoutItems.filter(item => (item.dValue[0] === itemType[newValue]));
    } else if (newValue === 6) {
      scoutItems = scoutItems.filter(item => ((item.dValue[0] >= 111) && (item.dValue[0] <= 113)));
    } else if (newValue === 7) {
      scoutItems = scoutItems.filter(item => ((item.dValue[0] >= 119) && (item.dValue[0] <= 125)));
    } else if (newValue === 8) {
      scoutItems = scoutItems.filter(item => ((item.dValue[0] >= 140) && (item.dValue[0] <= 145)));
    } else {
      scoutItems = items; // load all items since 0-All is selected
    }

    // Filter if not 0 || means load all if not 0
    if (activeTab2 > 0) {
      scoutItems = scoutItems.filter(item => (item.dValue[1] === activeTab2));
    }

    // Filter by job class
    if (activeTab3 > 0 && at3 === 0) {
      scoutItems = scoutItems.filter(item => (item.dValue[2] === job[activeTab3]));
    }

    setFilteredItems(scoutItems);
  };


  const handleTabChange2 = (event, newValue) => {
    setActiveTab2(newValue);
    setPage(0);

    const itemType = {
      0: 0,   // all
      1: 106, // weapon
      2: 105, // armor
      3: 108, // trousers
      4: 109, // shoes
      5: 103, // helm
      6: 111, // accessories    111 - 112 - 113
      7: 124, // transformation 119 ~ 125
      8: 145, // others         140 ~ 145
    }

    const job = {
      0: 0,      // 0 && 9 for all
      1: 101,    // Gunner
      2: 102,    // Snipe
      3: 103,    // Ranger
      4: 104,    // Magician
      5: 105,    // Guardian
      6: 106,    // Assassin
      7: 107,    // Shaman
      8: 108,    // Scout
    };

    // Tab1 = Item Type    || 0='' | 1=weapon | 2=Armor  | 3=Trousr | 4=Shoes    | 5=Helm  | 6=Accessories | 7=Transform | 8=Others
    // Tab2 = Rarity       || 0="" | 1=Normal | 2=Magic  | 3=Rare   | 4=Unique 
    // Tab3 = JobClass     || 0="" | 1=Gunner | 2=Sniper | 3=Ranger | 4=Magician | 5=Guardian | 6=Assassin | 7=Shaman | 8=Scout
    // dValue[0] = ItemType
    // dValue[1] = Rarity
    // dValue[2] = JobClass

    // load all unfiltereditems
    let scoutItems = items

    // Filter by rarity - newValuie
    if (newValue > 0) {
      scoutItems = scoutItems.filter(item => (item.dValue[1] === newValue));
    }

    //Filter ItemType dValue[0] || activeTab2
    if (activeTab1 > 0 && activeTab1 < 6) {
      scoutItems = scoutItems.filter(item => (item.dValue[0] === itemType[activeTab1]));
    } else if (activeTab1 === 6) {
      scoutItems = scoutItems.filter(item => ((item.dValue[0] >= 111) && (item.dValue[0] <= 113)));
    } else if (activeTab1 === 7) {
      scoutItems = scoutItems.filter(item => ((item.dValue[0] >= 119) && (item.dValue[0] <= 125)));
    } else if (activeTab1 === 8) {
      scoutItems = scoutItems.filter(item => ((item.dValue[0] >= 140) && (item.dValue[0] <= 145)));
    }

    //Filter ItemType dValue[0] || activeTab2
    if (activeTab3 > 0) {
      scoutItems = scoutItems.filter(item => (item.dValue[2] === job[activeTab3]));
    }

    setFilteredItems(scoutItems);
  };


  const handleTabChange3 = (event, newValue) => {
    setActiveTab3(newValue);
    setPage(0);

    const itemType = {
      0: 0,   // all
      1: 106, // weapon
      2: 105, // armor
      3: 108, // trousers
      4: 109, // shoes
      5: 103, // helm
      6: 111, // accessories    111 - 112 - 113
      7: 124, // transformation 119 ~ 125
      8: 145, // others         140 ~ 145
    }

    const job = {
      0: 0,      // 0 && 9 for all
      1: 101,    // Gunner
      2: 102,    // Snipe
      3: 103,    // Ranger
      4: 104,    // Magician
      5: 105,    // Guardian
      6: 106,    // Assassin
      7: 107,    // Shaman
      8: 108,    // Scout
    };

    // Tab1 = Item Type    || 0='' | 1=weapon | 2=Armor  | 3=Trousr | 4=Shoes    | 5=Helm  | 6=Accessories | 7=Transform | 8=Others
    // Tab2 = Rarity       || 0="" | 1=Normal | 2=Magic  | 3=Rare   | 4=Unique 
    // Tab3 = JobClass     || 0="" | 1=Gunner | 2=Sniper | 3=Ranger | 4=Magician | 5=Guardian | 6=Assassin | 7=Shaman | 8=Scout
    // dValue[0] = ItemType
    // dValue[1] = Rarity
    // dValue[2] = JobClass

    // load all unfiltereditems
    let scoutItems = items

    // Filter by job
    if (newValue > 0) {
      scoutItems = scoutItems.filter(item => (item.dValue[2] === job[newValue]));
    }

    //Filter ItemType dValue[0] || activeTab2
    if (activeTab1 > 0 && activeTab1 < 6) {
      scoutItems = scoutItems.filter(item => (item.dValue[0] === itemType[activeTab1]));
    } else if (activeTab1 === 6) {
      scoutItems = scoutItems.filter(item => ((item.dValue[0] >= 111) && (item.dValue[0] <= 113)));
    } else if (activeTab1 === 7) {
      scoutItems = scoutItems.filter(item => ((item.dValue[0] >= 119) && (item.dValue[0] <= 125)));
    } else if (activeTab1 === 8) {
      scoutItems = scoutItems.filter(item => ((item.dValue[0] >= 140) && (item.dValue[0] <= 145)));
    }

    // Filter if not 0 || means load all if not 0
    if (activeTab2 > 0) {
      scoutItems = scoutItems.filter(item => (item.dValue[1] === activeTab2));
    }

    setFilteredItems(scoutItems);
  };




  const fetchItems = async () => {
    try {
      // Original item data
      // const originalResponse = await axios.get('https://db.insideezulan.online/api/equipment'); // Change localhost to your IP

      // Modify item data to slice ac, divide specific numbers by 100, and round off to 1 decimal place
      // originalResponse.data.map(item)

      const modifiedResponse = jsonEquip.map(item => {
        const dividedDValue = item.ad.split('-').map(Number); // Split ac by '-' and convert each part to a number
        const newText = item.hasOwnProperty('text') ? item.text : ' '
        const textArray = newText.split('\n');

        // obtained skills
        const newSk = item.hasOwnProperty('skillsArr') ? item.skillsArr : ''
        const skArr = newSk.split('_').map(Number);


        // Divide specific numbers in the array by 100 and round off to 1 decimal place
        const dValue = dividedDValue.map((num, index) => {
          if ([11, 12, 13, 20, 21, 22, ...Array.from({ length: 10 }, (_, i) => 24 + i)].includes(index)) {
            return Math.round((num / 100) * 10) / 10; // Divide the number by 100 and round off to 1 decimal place
          }
          return num; // Keep the number unchanged if not in the specified index ranges
        });

        return {
          ...item,
          dValue, textArray, skArr, // Store the array of divided numbers in a new property
        };
      });

      setItems(modifiedResponse);
      setFilteredItems(modifiedResponse);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);


  return (
    <Container sx={{ pt: { xs: '0px' }, px: 0, cursor: theme.root.cursor, }}>
      <Box sx={{ width: '100%', cursor: theme.root.cursor, }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: theme.root.cursor, }}>
          <AntTabs value={activeTab1} onChange={handleTabChange1} aria-label="Item Type" variant="scrollable" scrollButtons="auto">
            <AntTab label="All" />              {/* 0 */}
            <AntTab label="Weapon" />           {/* 1 */}
            <AntTab label="Armor" />            {/* 2 */}
            <AntTab label="Trousers" />         {/* 3 */}
            <AntTab label="Shoes" />            {/* 4 */}
            <AntTab label="Helm" />             {/* 5 */}
            <AntTab label="Accessories" />      {/* 6 */}
            <AntTab label="Transformation" />   {/* 7 */}
            <AntTab label="Sealed" /> {/* 8 */}
          </AntTabs>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <AntTabs value={activeTab2} onChange={handleTabChange2} aria-label="Rarity" variant="scrollable" scrollButtons="auto">
            <AntTab label="All" />        {/* 0 */}
            <AntTab label="Normal" />     {/* 1 */}
            <AntTab label="Magic" />      {/* 2 */}
            <AntTab label="Rare" />       {/* 3 */}
            <AntTab label="Unique" />     {/* 4 */}
          </AntTabs>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <AntTabs value={activeTab3} onChange={handleTabChange3} aria-label="Class" variant="scrollable" scrollButtons="auto">
            <AntTab label="All" />         {/* 0 */}
            <AntTab label="Gunner" disabled={isTabDisabled} />      {/* 1 */}
            <AntTab label="Sniper" disabled={isTabDisabled} />      {/* 2 */}
            <AntTab label="Ranger" disabled={isTabDisabled} />      {/* 3 */}
            <AntTab label="Magician" disabled={isTabDisabled} />    {/* 4 */}
            <AntTab label="Guardian" disabled={isTabDisabled} />    {/* 5 */}
            <AntTab label="Assassin" disabled={isTabDisabled} />    {/* 6 */}
            <AntTab label="Shaman" disabled={isTabDisabled} />      {/* 7 */}
            <AntTab label="Scout" disabled={isTabDisabled} />       {/* 8 */}
          </AntTabs>
        </Box>

      </Box>
      <Box sx={{ py: 1, cursor: theme.root.cursor, }}>
        <p>{error}</p>
        {(
          (filteredItems.length > 0 ? <DataTable items={filteredItems} theme={theme} page={page} setPage={setPage} /> : <EmptyTable theme={theme} />)
        )}

      </Box>
    </Container>
  );
};

export default EquipmentPage;

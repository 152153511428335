import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const SkillTypography = ({ label, textStyle, sk, theme }) => {

  const SpanTypography = styled(Typography)({
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
    color: theme.palette.tooltip.row,
    lineHeight: 1.4,
  });

  // jobClass = item.dValue[2]
  const jobClass = {
    0: 'Gunnah',
    1: 'Gunner',
    2: 'Sniper',
    4: 'Ranger',
    8: 'Magician',
    16: 'Guardian',
    32: 'Assassin',
    64: 'Shaman',
    128: 'Scout',
  }

  let eqSkDesc = ""
  // if (sk.desc !== '' && sk.desc !== undefined) {
  //   eqSkDesc = sk.desc.split('\n');
  // }
  if (sk && typeof sk === 'object' && sk.hasOwnProperty('desc')) {
    eqSkDesc = sk.desc.split(/\n/);

  }


  const getMinutes = (milliseconds) => {
    // Convert milliseconds to seconds
    var totalSeconds = milliseconds / 100;
    // Calculate minutes and remaining seconds
    var minutes = Math.floor(totalSeconds / 60);
    return minutes;
  }

  const getSeconds = (milliseconds) => {
    // Convert milliseconds to seconds
    var totalSeconds = milliseconds / 100;
    // Calculate minutes and remaining seconds
    var seconds = totalSeconds % 60;
    return seconds;
  }

  const getHours = (milliseconds) => {
    // Convert milliseconds to seconds
    var totalSeconds = milliseconds / 100;
    // Calculate minutes and remaining seconds
    var minutes = Math.floor(totalSeconds / 60);
    var hours = 0
    if (minutes >= 60) {
      hours = Math.floor(minutes / 60);
    }
    return hours;
  }


  const getTime = (seconds) => {
    let str = ''
    if (getHours(seconds) > 0) {
      str += `${getHours(seconds)} hour(s)`;
    } else {
      if (getMinutes(seconds) > 0) {
        str += `${getMinutes(seconds)} M `
      }
    }

    (getSeconds(seconds) > 0) && (str += `${getSeconds(seconds)} S`)
    return str
  }

  return (
    <>
      <>
        {(label === 'filler' && <SpanTypography variant="subtitle1" sx={textStyle}> </SpanTypography>)}

        {(label === 'name' && <SpanTypography variant="subtitle1" sx={textStyle}>{sk.name}</SpanTypography>)}
        {(label === 'type' && <SpanTypography variant="subtitle1" sx={textStyle}>{sk.type === 1 ? 'Passive' : 'Active'} Skill</SpanTypography>)}

        {(label === 'mp' && <SpanTypography variant="subtitle1" sx={textStyle}>MP Used {sk.consumeMp}</SpanTypography>)}

        {(label === 'weapon' && <SpanTypography variant="subtitle1" sx={textStyle}>Weapon Required</SpanTypography>)}

        {(label === 'job' && <SpanTypography variant="subtitle1" sx={textStyle}>{jobClass[sk.activeSkillUsePc]}</SpanTypography>)}

        {(label === 'target' && <SpanTypography variant="subtitle1" sx={textStyle}>{sk.targetType === 4 ? `Target-Enemy` : (sk.targetType === 9 ? `Target-Self, Ally` : (sk.targetType === 3 ? `Target-Self, PC` : `Instant Use`))}</SpanTypography>)}
        {(label === 'range' && <SpanTypography variant="subtitle1" sx={textStyle}>Range {(sk.range / 10).toFixed(1)} Meter</SpanTypography>)}

        {(label === 'cd' && <SpanTypography variant="subtitle1" sx={textStyle}>
          Cooldown {getTime(sk.driveBackDelay)}

        </SpanTypography>)}

        {(label === 'duration' && <SpanTypography variant="subtitle1" sx={textStyle}>
          Duration {(getHours(sk.effDuration) > 0) ? <>{getHours(sk.effDuration)} hour(s)</> : (getMinutes(sk.effDuration) > 0) && <>{getMinutes(sk.effDuration)} M</>} {(getSeconds(sk.effDuration) > 0) && <>{getSeconds(sk.effDuration)} S</>}
        </SpanTypography>)}

        {(label === 'desc' && <SpanTypography variant="subtitle1" sx={textStyle}>{sk.desc}</SpanTypography>)}

        {(label === 'req' && <SpanTypography variant="subtitle1" sx={textStyle}>Training Pre-Requisite {sk.needSkil}</SpanTypography>)}
        {(label === 'level' && <SpanTypography variant="subtitle1" sx={textStyle}>Trained Lv {sk.reqLv}</SpanTypography>)}

        {(label === 'code' && <SpanTypography variant="subtitle1" sx={textStyle}>Skill Code {sk.code}</SpanTypography>)}
      </>

      <>
        {(label === 'filler' && <SpanTypography variant="subtitle1" sx={textStyle}> </SpanTypography>)}
        {(label === 'eqName' && <SpanTypography variant="subtitle1" sx={textStyle}>Report this to Dev</SpanTypography>)}
        {(label === 'eqType' && <SpanTypography variant="subtitle1" sx={textStyle}>{sk.type === 1 ? 'Passive' : 'Active'} Skill</SpanTypography>)}
        {(label === 'eqMp' && <SpanTypography variant="subtitle1" sx={textStyle}>MP Used {sk.consumeMp}</SpanTypography>)}

        {(label === 'eqJob' && <SpanTypography variant="subtitle1" sx={textStyle}>{jobClass[sk.activeSkillUsePc]}</SpanTypography>)}

        {(label === 'eqTarget' && <SpanTypography variant="subtitle1" sx={textStyle}>{sk.targetType === 4 ? `Target-Enemy` : (sk.targetType === 9 ? `Target-Self, Ally` : (sk.targetType === 3 ? `Target-Self, PC` : `Instant Use`))}</SpanTypography>)}
        {(label === 'eqRange' && <SpanTypography variant="subtitle1" sx={textStyle}>Range {(sk.range / 10).toFixed(1)} Meter</SpanTypography>)}

        {(label === 'eqCd' && <SpanTypography variant="subtitle1" sx={textStyle}>
          Cooldown {getTime(sk.driveBackDelay)}

        </SpanTypography>)}

        {(label === 'eqDuration' && <SpanTypography variant="subtitle1" sx={textStyle}>
          Duration {getTime(sk.effDuration)}
        </SpanTypography>)}

        {(label === 'eqDesc' && eqSkDesc.length === 1 && <SpanTypography variant="subtitle1" sx={textStyle}>{sk.desc}</SpanTypography>)}

        {((label === 'eqDesc' && eqSkDesc.length > 1) && (
          <SpanTypography sx={{ ...textStyle, lineHeight: 1.35, py: '3px' }}>
            {Object.keys(eqSkDesc).map((key, index) => (
              <React.Fragment key={index}>
                {eqSkDesc[key]}<br />
              </React.Fragment>
            ))}
          </SpanTypography>
        ))}
      </>

    </>

  );
};


export default SkillTypography;



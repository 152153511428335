import { Link } from 'react-router-dom'; // Import Link for navigation

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const HomePage = ({ theme }) => {

  const cards = [
    { id: 1, title: 'Items', description: 'Drops | Cash Shop | Quest Items', image: '/images/150046b.png', route: '/items' },
    { id: 2, title: 'Equipment Sets', description: 'Magic | Rare | Unique | ', image: '/images/150048b.png', route: '/equipment' },
    { id: 3, title: 'Monsters', description: 'Fields | Dungeons | Bosses', image: '/images/151014.png', route: '/monsters' },
    { id: 4, title: 'Skills', description: 'Skills | Full Stat Simulator', image: '/images/6423453.png', route: '/skills' },
    { id: 5, title: 'Quests', description: 'Main | Side Quests', image: '/images/140400008.png', route: '/quests' },
    { id: 6, title: 'Dragona Image Sets', description: 'Loading Screens | NPC Images', image: '/images/loading01.jpg', route: '/images' },
  ];

  return (
    <Container sx={{ paddingTop: { xs: '4px', sm: '7px', md: '15px', lg: '40px' } }}>



      <Grid container spacing={3}>
        {cards.map((card) => (
          <Grid item xs={12} sm={6} md={4} key={card.id}>
            <Link style={{ cursor: theme.root.handCursor, textDecoration: 'none' }} to={`${card.route}`} >
              <Card sx={{ m: '10px' }}>
                <CardMedia
                  component="img"
                  height="110"
                  image={card.image}
                  title={card.title}
                  alt={card.title}

                />
                <CardContent >
                  <Typography gutterBottom variant="h5" component="h2" >
                    {card.title}
                  </Typography>
                  <Typography sx={{ fontFamily: 'Roboto' }} variant="body2" component="p" >
                    {card.description}
                    {((card.id === 2 ? <span style={{ textDecoration: 'line-through' }}>Legendary</span> : ""))}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HomePage;

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import SkillTypography from './SkillTypography';

import bgaa from '../assets/aa.png';
import bgab from '../assets/ab.png';
import bgac from '../assets/ac.png';
import bgba from '../assets/ba.png';
import bgbc from '../assets/bc.png';
import bgca from '../assets/ca.png';
import bgcb from '../assets/cb.png';
import bgcc from '../assets/cc.png';

import allSkills from "../json/allSkillsv4.json"
import metaSk1 from "../json/metaSk1.json"

const SkillPop = ({ skCode, skType, theme }) => {
  const [sk, setSk] = useState({});
  const [eqSk, setEqSk] = useState({});

  const boxWrap = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0),
    padding: 0,
    paddingLeft: '7px',
  }

  const boxWrapInner = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    paddingRight: '7px',
    width: '50%',
  }

  // useEffect with prop dependency
  useEffect(() => {
    const filteredSk = allSkills.find(sk => sk.code === skCode)
    const filteredSk2 = metaSk1.find(sk => sk.code === skCode)


    const modifyJsonItem = (jsonItem) => {
      if (jsonItem && jsonItem.hasOwnProperty('desc')) {
        jsonItem.descArray = jsonItem.desc.split('\n');
      }
    };

    setSk(filteredSk)
    setEqSk(filteredSk2)
  }, [skCode]);


  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '300px', height: 'auto', backgroundColor: theme.palette.tooltip.bg, border: "1px solid rgba(2,2,2,.02)", borderRadius: '3px' }}>

        <Box sx={{ backgroundImage: `url(${bgaa})`, width: '5px', height: '3px', }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgab})`, width: '288px', height: '3px', backgroundSize: '100% 100%', }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgac})`, width: '5px', height: '3px' }} ></Box>

        <Box sx={{ backgroundImage: `url(${bgba})`, width: '3px', minHeight: '5px', backgroundSize: '100% 100%', }} ></Box>
        <Box sx={{ width: '292px', minHeight: '5px', gap: theme.spacing(0), }} >
          {(skType !== 'eq') &&
            <React.Fragment>
              <Box sx={{ ...boxWrap, pt: '4px' }}>
                <SkillTypography label='name' sk={sk} textStyle={{ width: '50%', color: 'rgb(220, 200, 53)' }} theme={theme} />
                <Box sx={boxWrapInner}>
                  <SkillTypography label='type' sk={sk} theme={theme} />
                </Box>
              </Box>

              <Box sx={{ mt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />

              <Box sx={{ ...boxWrap, pt: '4px' }}>
                {sk.consumeMp > 0 &&
                  <>
                    <SkillTypography label='mp' sk={sk} theme={theme} textStyle={{ width: '50%' }} />
                    <Box sx={boxWrapInner}><SkillTypography label='filler' theme={theme} /></Box>
                  </>}

                {sk.needWeapon > 0 &&
                  <>
                    <Box sx={boxWrapInner}><SkillTypography label='filler' theme={theme} textStyle={{ width: '50%' }} /></Box>
                    <Box sx={boxWrapInner}><SkillTypography label='weapon' theme={theme} /></Box>
                  </>}

                <Box sx={boxWrapInner}><SkillTypography label='filler' theme={theme} textStyle={{ width: '50%' }} /></Box>
                <Box sx={boxWrapInner}><SkillTypography sk={sk} label='job' theme={theme} /></Box>
              </Box>
              {sk.type !== 1 && <>
                <Box sx={{ mt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />

                <Box sx={{ ...boxWrap, pt: '4px' }}>


                  <SkillTypography label='target' sk={sk} theme={theme} textStyle={{ width: '50%' }} />
                  {sk.range > 0 ? <Box sx={boxWrapInner}><SkillTypography label='range' sk={sk} theme={theme} /></Box> : <Box sx={boxWrapInner}><SkillTypography label='filler' theme={theme} /></Box>}

                  <Box sx={boxWrapInner}><SkillTypography label='filler' theme={theme} textStyle={{ width: '50%' }} /></Box>
                  <Box sx={boxWrapInner}><SkillTypography label='cd' sk={sk} theme={theme} /></Box>


                  {sk.effDuration > 0 &&
                    <>
                      <SkillTypography label='duration' sk={sk} theme={theme} textStyle={{ width: '50%' }} />
                      <Box sx={boxWrapInner}><SkillTypography label='filler' theme={theme} /></Box>
                    </>}
                </Box>
              </>
              }

              <Box sx={{ mt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />

              <Box sx={{ ...boxWrap, pt: '4px', pr: '4px' }}>
                <SkillTypography label='desc' sk={sk} theme={theme} />
              </Box>

              <Box sx={{ mt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />

              <Box sx={{ ...boxWrap, pt: '4px' }}>
                {sk.needSkil !== 0 && <SkillTypography label='req' sk={sk} theme={theme} textStyle={{ width: '100%' }} />}
                <SkillTypography label='level' sk={sk} theme={theme} textStyle={{ width: '100%' }} />
              </Box>

              <Box sx={{ mt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />

              <Box sx={{ ...boxWrap, pt: '4px' }}>
                <SkillTypography label='code' sk={sk} theme={theme} />
              </Box>

            </React.Fragment>
          }

          {(skType === 'eq') &&
            <React.Fragment>

              <Box sx={{ ...boxWrap, pt: '4px' }}>
                <SkillTypography label='eqName' sk={eqSk} textStyle={{ width: '50%', color: 'rgb(220, 200, 53)' }} theme={theme} />
                <Box sx={boxWrapInner}>
                  <SkillTypography label='eqType' sk={eqSk} theme={theme} />
                </Box>
              </Box>

              {((eqSk.consumeMp > 0) || eqSk.activeSkillUsePc !== 255) && <>
                <Box sx={{ mt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />

                <Box sx={{ ...boxWrap, pt: '4px' }}>
                  {eqSk.consumeMp > 0 &&
                    <>
                      <SkillTypography label='eqMp' sk={eqSk} theme={theme} textStyle={{ width: '50%' }} />
                      <Box sx={boxWrapInner}><SkillTypography label='filler' theme={theme} /></Box>
                    </>}
                  <Box sx={boxWrapInner}><SkillTypography label='filler' theme={theme} textStyle={{ width: '50%' }} /></Box>
                  <Box sx={boxWrapInner}><SkillTypography sk={eqSk} label='eqJob' theme={theme} /></Box>
                </Box>
              </>}
              {eqSk.type !== 1 && <>
                <Box sx={{ mt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />

                <Box sx={{ ...boxWrap, pt: '4px' }}>
                  <SkillTypography label='eqTarget' sk={eqSk} theme={theme} textStyle={{ width: '50%' }} />
                  {eqSk.range > 0 ? <Box sx={boxWrapInner}><SkillTypography label='eqRange' sk={eqSk} theme={theme} /></Box> :
                    <Box sx={boxWrapInner}><SkillTypography label='filler' theme={theme} /></Box>}

                  <Box sx={boxWrapInner}><SkillTypography label='filler' theme={theme} textStyle={{ width: '50%' }} /></Box>
                  <Box sx={boxWrapInner}><SkillTypography label='eqCd' sk={eqSk} theme={theme} /></Box>

                  {eqSk.effDuration > 0 &&
                    <>
                      <SkillTypography label='eqDuration' sk={eqSk} theme={theme} textStyle={{ width: '50%' }} />
                      <Box sx={boxWrapInner}><SkillTypography label='filler' theme={theme} /></Box>
                    </>}
                </Box>
              </>
              }

              <Box sx={{ mt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />


              <Box sx={{ ...boxWrap, pt: '4px', pr: '4px' }}>
                <SkillTypography label='eqDesc' sk={eqSk} theme={theme} />
              </Box>

              <Box sx={{ mt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />

              <Box sx={{ ...boxWrap, pt: '4px' }}>
                <SkillTypography label='code' sk={eqSk} theme={theme} />
              </Box>


            </React.Fragment>
          }
        </Box>
        <Box sx={{ backgroundImage: `url(${bgbc})`, width: '3px', minHeight: '5px', backgroundSize: '100% 100%', }} ></Box>

        <Box sx={{ backgroundImage: `url(${bgca})`, width: '5px', height: '3px' }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgcb})`, width: '288px', height: '3px', backgroundSize: '100% 100%', }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgcc})`, width: '5px', height: '3px' }} ></Box>
      </Box >
    </>

  );
}



export default SkillPop;


import React from 'react';
import { Box } from '@mui/material';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import EquipTypography from './EquipTypography';
import SkillPop from './SkillPop';


import bgaa from '../assets/aa.png';
import bgab from '../assets/ab.png';
import bgac from '../assets/ac.png';
import bgba from '../assets/ba.png';
import bgbc from '../assets/bc.png';
import bgca from '../assets/ca.png';
import bgcb from '../assets/cb.png';
import bgcc from '../assets/cc.png';

import metaSk from '../json/metaSk1.json'


// Function to filter objects by property value
function filterObjectsByProperty(skCode) {
  return metaSk.find(obj => obj['code'] === skCode);
}



const EquipPop = ({ item, theme }) => {

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      color: theme.palette.tooltip.text,
    },
  }));


  const boxWrap = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0),
    paddingLeft: '6px',
    lineHeight: 1.4,
  }

  const statLabelStyle = {
    fontWeight: '300',
  }

  const randomStyle = {
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '300px', height: 'auto', backgroundColor: theme.palette.tooltip.bg, border: "1px solid rgba(2,2,2,.02)", borderRadius: '3px' }}>

        <Box sx={{ backgroundImage: `url(${bgaa})`, width: '5px', height: '3px', }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgab})`, width: '288px', height: '3px', backgroundSize: '100% 100%', }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgac})`, width: '5px', height: '3px' }} ></Box>

        <Box sx={{ backgroundImage: `url(${bgba})`, width: '3px', minHeight: '20px', backgroundSize: '100% 100%', }} ></Box>
        <Box sx={{ width: '292px', minHeight: '20px', }} >


          <Box style={boxWrap} sx={{ pt: '4px' }}>
            <EquipTypography label='itemNameStyles' item={item} theme={theme} />
          </Box>

          <Box style={boxWrap}>
            <EquipTypography label='itemType' item={item} theme={theme} textStyle={{ minWidth: '180px' }} />
          </Box>

          <Box sx={{ m: 0, borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />

          {
            <Box style={boxWrap} sx={{ pt: '3px' }}>
              <EquipTypography label='jobClass' item={item} theme={theme} textStyle={{ width: '50%' }} />
              <Box style={boxWrap} sx={{ pr: '7px', width: '50%', justifyContent: 'flex-end' }}>
                <EquipTypography label='noLabel' item={`Required Lv. ${item.dValue[3] - 1000}`} theme={theme} />
              </Box>
            </Box>
          }

          {item.dValue[0] === 145 && item.dValue[4] !== 0 &&
            <Box style={boxWrap} >
              {(item.dValue[0] === 145 && (<EquipTypography label='noLabel' item={'Instant Use'} theme={theme} textStyle={{ width: '50%' }} />))}
              {(item.dValue[0] !== 145 && (<EquipTypography label='noLabel' item={' '} theme={theme} textStyle={{ width: '50%' }} />))}
              {(item.dValue[4] !== 0 && item.dValue[0] === 145 && (
                <Box style={boxWrap} sx={{ pr: '7px', width: '50%', justifyContent: 'flex-end' }}>
                  <EquipTypography label='noLabel' item={`Cooldown ${item.dValue[4] / 100} s`} theme={theme} />
                </Box>
              ))}
            </Box>
          }

          {(((item.dValue[0] >= 103) && (item.dValue[0] <= 110)) &&
            <Box style={boxWrap}>
              <EquipTypography label='noLabel' item={'Maximum Reinforcement Lv'} theme={theme} />
            </Box>
          )}

          {(((item.dValue[0] >= 111) && (item.dValue[0] <= 113)) &&
            <Box style={boxWrap}>
              <EquipTypography label='noLabel' item={'Cannot be reinforced'} theme={theme} />
            </Box>
          )}

          {(((item.dValue[0] !== 140) && (item.dValue[0] !== 145)) &&
            <><Box sx={{ m: 0, borderTop: `1px solid ${theme.palette.tooltip.divider}` }} /></>
          )}

          {(((item.dValue[0] >= 103) && (item.dValue[0] <= 113)) ?
            <Box style={boxWrap} sx={{ pt: '4px', pb: '4px' }}>
              <EquipTypography label='noLabel' item={'Effect'} theme={theme} />
            </Box>
            :
            null
          )}

          {(item.dValue[5] !== 0 && (
            <Box style={boxWrap} sx={{ pl: '22px' }}>
              <EquipTypography label='attack' item={item} theme={theme} />
            </Box>
          ))}

          {Object.keys(item.dValue).slice(7).map(key => (
            item.dValue[key] !== 0 && (
              <Box key={key} style={boxWrap} sx={{ pl: '22px', }}>

                {((key < 34) && // skip dValue34 (projectile speed)
                  ((([16, 19].includes(Number(key))) && (item.dValue[key] > 80))
                    ?
                    <EquipTypography label='eLabels' itemKey={key} item={item} theme={theme} textStyle={statLabelStyle} />
                    :
                    <EquipTypography label='dLabels' itemKey={key} item={item} theme={theme} textStyle={statLabelStyle} />
                  )
                )}

              </Box>
            )
          ))}

          <Box>
            {((item.text !== undefined) &&

              <React.Fragment>
                <Box sx={{ mt: '5px', pt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />
                <EquipTypography label='text' item={item} theme={theme} />
              </React.Fragment>
            )}
          </Box>

          <Box>
            {((item.skillsArr !== undefined) && (item.skillsArr !== "") && (
              <React.Fragment>
                <Box sx={{ mt: '5px', pt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}` }} />
                <Box style={boxWrap} sx={{ pl: '22px' }}>
                  <EquipTypography label='noLabel' item={'Skills Obtained'} theme={theme} textStyle={{ width: '100%' }} />
                  <Box sx={{ ...boxWrap, pl: 0, ml: '-6px', width: "100%" }} style={item.skArr.length <= 2 ? randomStyle : null} >
                    {item.skArr.map(key => (
                      <React.Fragment key={key}>

                        {(filterObjectsByProperty(parseInt(key))) !== undefined &&
                          <HtmlTooltip title={<> <SkillPop skCode={key} skType={'eq'} theme={theme} /> </>} placement="right-start" >
                            <Box style={boxWrap} sx={{ pt: '3px', pb: '6px', width: '16%' }}>
                              <img src={`images/skills01/${(filterObjectsByProperty(parseInt(key))).ic}.png`} alt={item.name} />
                            </Box>
                          </HtmlTooltip>
                        }
                      </React.Fragment>

                    ))}
                  </Box>
                </Box>
              </React.Fragment>))}
          </Box>
        </Box>

        <Box sx={{ backgroundImage: `url(${bgbc})`, width: '3px', minHeight: '20px', backgroundSize: '100% 100%', }} ></Box>

        <Box sx={{ backgroundImage: `url(${bgca})`, width: '5px', height: '3px' }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgcb})`, width: '288px', height: '3px', backgroundSize: '100% 100%', }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgcc})`, width: '5px', height: '3px' }} ></Box>
      </Box >
    </>

  );
}



export default EquipPop;


import React from 'react';
import { Box } from '@mui/material';
import ItemTypography from './ItemTypography';

import bgaa from '../assets/aa.png';
import bgab from '../assets/ab.png';
import bgac from '../assets/ac.png';
import bgba from '../assets/ba.png';
import bgbc from '../assets/bc.png';
import bgca from '../assets/ca.png';
import bgcb from '../assets/cb.png';
import bgcc from '../assets/cc.png';


const ItemPop = ({ item, theme }) => {

  const boxWrap = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0),
    paddingLeft: '7px',
  }

  const filteredStats = item.statsArray.filter((item, index) => index >= 27 && index <= 67);

  const statSum = filteredStats.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const statLabelStyle = {
    lineHeight: 1.3,
    minWidth: '160px',
    pl: '18px',
    color: theme.palette.tooltip.label,
    fontWeight: '300',
  }

  return (
    <>
      <Box sx={{display: 'flex', flexWrap: 'wrap', width: '300px', height:'auto' , backgroundColor: theme.palette.tooltip.bg, border:"1px solid rgba(2,2,2,.02)", borderRadius: '3px'}}>

        <Box sx={{ backgroundImage: `url(${bgaa})`, width: '5px', height: '3px',  }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgab})`, width: '288px', height: '3px', backgroundSize: '100% 100%',}} ></Box>
        <Box sx={{ backgroundImage: `url(${bgac})`, width: '5px', height: '3px'  }} ></Box>

        <Box sx={{ backgroundImage: `url(${bgba})`, width: '3px', minHeight: '5px', backgroundSize: '100% 100%',}} ></Box>
        <Box sx={{ width: '292px', minHeight: '5px', gap: theme.spacing(0),}} >

          <Box style={boxWrap} sx={{pt: '3px', pb: 0, m: 0}}>
            <ItemTypography label='nameStyles' item={item} theme={theme} />
          </Box>

          <Box style={boxWrap} sx={{pt: 0, }}>
            <ItemTypography label='sort' item={item} theme={theme} textStyle={{width: '50%'}} />
            <Box style={boxWrap} sx={{pt: 0, pr: '7px', width: '50%', justifyContent: 'flex-end'}}>
              <ItemTypography label='slot' item={item} theme={theme} />
            </Box>
          </Box>

          { ((item.statsArray[6] > 0 && item.statsArray[6] <= 128) || ((item.lvlreq > 0) && item.code!== 120210043 ) || (item.statsArray[5] === 0)) &&
          <Box sx={{mt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}`}} />
          }

          { ((item.statsArray[6] > 0 && item.statsArray[6] <= 128) || ((item.lvlreq > 0) && item.code!== 120210043 ) || (item.statsArray[5] === 0)) &&
          <Box style={boxWrap} sx={{pt: '3px'}}>
            {(item.sort !== 47 ? <ItemTypography label='jobClass' item={item} theme={theme} textStyle={{width: '50%'}} /> : <ItemTypography label='filler' theme={theme} textStyle={{width: '50%'}} />)}
            <Box style={boxWrap} sx={{pt: 0, pr: '7px', width: '50%', justifyContent: 'flex-end'}}>
              {( item.statsArray[7] > 0 && <ItemTypography  label='reqLv' item={item} textValue={item.statsArray[7]} theme={theme} /> )}
              {( item.statsArray[25] > 0 && <ItemTypography label='reqLv' item={item} textValue={item.statsArray[25]} theme={theme} /> )}
            </Box>
          </Box>
          }

          {( item.statsArray[5] === 0 &&
            <>
              <Box style={boxWrap} sx={{pt: 0, pr: '7px', justifyContent: 'flex-end'}}>
                <ItemTypography label='trade' textValue={'Cannot Trade'} theme={theme} />
              </Box>
            </> )}

          {( ( (item.sort === 30) || (item.sort === 39) || ((item.sort >= 44) && (item.sort <= 47)) || (item.sort === 49) ) && 
            <Box style={boxWrap} sx={{pt: 0}}>
              <ItemTypography label='usage'theme={theme} textStyle={{width: '50%'}} /> 
              <Box style={boxWrap} sx={{pt: 0, pr: '7px', width: '50%', justifyContent: 'flex-end'}}><ItemTypography label='range' item={item} theme={theme} /></Box>
            </Box>
          )}

          {( item.sort === 41 || item.sort === 42) &&
          <Box style={boxWrap} sx={{pt: 0}}>
            <ItemTypography label='cannotrein' item={item} theme={theme} textStyle={{width: '50%'}} />
          </Box>   
          }

          {( item.statsArray[26] > 0 &&
          <Box style={boxWrap} sx={{pt: 0}}>
            <ItemTypography label='duration' item={item} theme={theme} textStyle={{width: '50%'}} />
            <Box style={boxWrap} sx={{pt: 0, pr: '7px', width: '50%', justifyContent: 'flex-end'}}><ItemTypography label='cd' item={item} theme={theme} /></Box>
          </Box>   
          )}
          {( item.statsArray[26] === 0 && item.statsArray[24] > 0 &&
          <Box style={boxWrap} sx={{pt: 0, pr: '7px', justifyContent: 'flex-end'}}>
            <ItemTypography label='cd' item={item} theme={theme} />
          </Box>   
          )}
            

          {(
            ((statSum > 0 && (item.sort !== 15) && (item.sort !== 40)) || item.sort === 29  ) && 
            <Box style={boxWrap} sx={{pt:'4px', borderTop: `1px solid ${theme.palette.tooltip.divider}`}} >
              <ItemTypography label='text' textValue={'Effect:'} theme={theme} textStyle={{minWidth: '100%'}} />
            </Box>
          )}

          {((statSum > 0 && (item.sort !== 15) && (item.sort !== 40)) || item.sort === 29  ) &&
          <Box style={boxWrap}>
            {filteredStats.map((stat, index) => (
              ((stat > 0) && (item.sort !== 15) && (item.sort !== 40)) &&
                  <React.Fragment key={index} >
                    <ItemTypography label='sLabels' statIndex={index} item={stat} theme={theme} textStyle={statLabelStyle} /> 
                    <Box style={boxWrap} >
                      <ItemTypography label='stat' statIndex={index} item={stat} theme={theme} textStyle={{lineHeight: 1.3, color: theme.palette.rarity.magic}}/>
                    </Box>
                  </React.Fragment>
              ))}
            </Box>
            }

            {( item.sort === 15 && 
              <Box style={boxWrap} sx={{pt:'4px',mt: '3px', borderTop: `1px solid ${theme.palette.tooltip.divider}`}} >
                <ItemTypography label='vehicleName' item={item} theme={theme} textStyle={{width: '100%', lineHeight:1.3,}} />
                <ItemTypography label='vehicleSpeed' item={item} theme={theme} textStyle={{width: '100%', lineHeight:1.3,}} />
              </Box>
            )}
            {( item.sort === 29 && 
              <Box style={boxWrap} sx={{ }}>
                <ItemTypography label='gxp' item={item} theme={theme} textStyle={{...statLabelStyle, width: '100%'}} />
                <ItemTypography label='gh' item={item} theme={theme} textStyle={{...statLabelStyle, width: '100%'}} />
                <ItemTypography label='gsp' item={item} theme={theme} textStyle={{...statLabelStyle, width: '100%'}} />
              </Box>
            )}
            {( item.code >= 110200254 && item.code <= 110200259 &&
              <Box style={boxWrap} sx={{pt:'4px', borderTop: `1px solid ${theme.palette.tooltip.divider}`}} >
                 <ItemTypography label='text' textValue={'Effect:'} theme={theme} textStyle={{minWidth: '100%'}} />
                 <ItemTypography label='honor1' theme={theme} textStyle={statLabelStyle} /> 
                  <Box style={boxWrap} >
                    <ItemTypography label='honor2' item={item} theme={theme} textStyle={{lineHeight: 1.3, color: theme.palette.rarity.magic}}/>
                  </Box>
              </Box>
            )}
            {( item.code === 110210008 || item.code === 120210008 || item.code === 120210009 ) &&
              <Box style={boxWrap} sx={{pt:'4px', borderTop: `1px solid ${theme.palette.tooltip.divider}`}} >
                 <ItemTypography label='text' textValue={'Effect:'} theme={theme} textStyle={{minWidth: '100%'}} />
                 <ItemTypography label='boil' theme={theme} textStyle={statLabelStyle} /> 
              </Box>
            }

            {( (item.code >= 110200143 && item.code <= 110200154) || (item.code >= 110210048 && item.code <= 110210055) || (item.code >= 110210063 && item.code <= 110210086) || (item.code === 110210104) || (item.code === 120210046) || (item.code === 120210047)) && 
              <Box style={boxWrap} sx={{pt:'4px', borderTop: `1px solid ${theme.palette.tooltip.divider}`}} >
                 <ItemTypography label='text' textValue={'Effect:'} theme={theme} textStyle={{minWidth: '100%'}} />
                 <ItemTypography label='portal' theme={theme} textStyle={statLabelStyle} /> 
              </Box>
            }

            {((item.code === 110210009) || (item.code === 120210005) ) && 
              <Box style={boxWrap} sx={{pt:'4px', borderTop: `1px solid ${theme.palette.tooltip.divider}`}} >
                 <ItemTypography label='text' textValue={'Effect:'} theme={theme} textStyle={{minWidth: '100%'}} />
                 <ItemTypography label='skillresetp' theme={theme} textStyle={statLabelStyle} /> 
              </Box>
            }
            {((item.code === 110210010) || (item.code === 120210004) || (item.code === 120210042) ) && 
              <Box style={boxWrap} sx={{pt:'4px', borderTop: `1px solid ${theme.palette.tooltip.divider}`}} >
                 <ItemTypography label='text' textValue={'Effect:'} theme={theme} textStyle={{minWidth: '100%'}} />
                 <ItemTypography label='skillresett' theme={theme} textStyle={statLabelStyle} /> 
              </Box>
            }
            {((item.code >= 110740506) && (item.code <= 110740508) ) && 
              <Box style={boxWrap} sx={{pt:'4px', borderTop: `1px solid ${theme.palette.tooltip.divider}`}} >
                 <ItemTypography label='sealedancient' item={item} theme={theme} textStyle={statLabelStyle} /> 
              </Box>
            }
            {(item.code === 110740519)  && 
              <Box style={boxWrap} sx={{pt:'4px', borderTop: `1px solid ${theme.palette.tooltip.divider}`}} >
                 <ItemTypography label='sealedancient45' item={item} theme={theme} textStyle={statLabelStyle} /> 
              </Box>
            }



          
          {(((item.desc !== "") && (item.desc !== undefined) && (item.desc !== 0) && (item.desc !== '0'))  &&
            <Box sx={{ pt: '4px', mt: '4px', borderTop: `1px solid ${theme.palette.tooltip.divider}`}} />
            )}

          {((item.desc !== '') && 
                <Box style={boxWrap} sx={{pr: '4px'}}>
                      <ItemTypography label='desc' item={item} theme={theme} />
                </Box>
          )}



        </Box>
        <Box sx={{ backgroundImage: `url(${bgbc})`, width: '3px', minHeight: '5px', backgroundSize: '100% 100%',}} ></Box>

        <Box sx={{ backgroundImage: `url(${bgca})`, width: '5px', height: '3px' }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgcb})`, width: '288px', height: '3px', backgroundSize: '100% 100%',}} ></Box>
        <Box sx={{ backgroundImage: `url(${bgcc})`, width: '5px', height: '3px'  }} ></Box>
      </Box>
    </>   

  );  
}



export default ItemPop;


import React, { useState, useEffect } from 'react';
import { Container, Box, TextField } from '@mui/material';
import QuestTable from './components/QuestTable';
import EmptyTable from './components/EmptyTable';


import quests from './json/qsv1.json';

const QuestPage = ({ currentUrl, theme }) => {
  const [filteredQs, setFilteredQs] = useState(quests);
  const [page, setPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');




  const handleSearch = (event) => {
    setPage(0);

    // Remove non-alphanumeric characters using regular expression
    const stringWithoutSpecialChars = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

    setSearchFilter(stringWithoutSpecialChars)


    // load all unfiltered items
    let scoutItems = filteredQs

    try {
      const wordToCheck = stringWithoutSpecialChars;
      const regex = new RegExp(wordToCheck, 'i'); // 'i' flag for case-insensitive matching
      scoutItems = scoutItems.filter(obj => regex.test(obj.qName));

    } catch (error) {
      console.log(error)
    }


    if (stringWithoutSpecialChars === '') {
      scoutItems = quests
    }

    setFilteredQs(scoutItems);
  }


  useEffect(() => {


  }, [filteredQs]);



  return (
    <Container sx={{ pt: { xs: '5px', md: '20px' }, px: 0, cursor: theme.root.cursor, }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <div sx={{ width: '400px' }} >
          <TextField id="outlined-search" label="Search field" value={searchFilter} type="search" onChange={handleSearch} />
        </div>
      </Box>
      <Box sx={{ py: 1, cursor: theme.root.cursor, }}>
        {(
          (filteredQs.length > 0 ? <QuestTable qs={filteredQs} theme={theme} page={page} setPage={setPage} /> : <EmptyTable theme={theme} />)
        )}

      </Box>
    </Container>
  );
};

export default QuestPage;

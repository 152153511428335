import React, { useState } from 'react';
import { Box } from '@mui/material';

const JobHover = ({ jobNum, isSelected, handleJobClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const boxWrap = {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: '8px',
  }

  const subImgContainer = {
    display: 'flex',
    alignItems: 'center',
  }

  const imgContainer = {
    position: 'relative',
    width: '22px',
    height: '23px',
  }

  const imgStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }

  return (
    <>
      <Box style={{ ...boxWrap, ...subImgContainer }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleJobClick}
        id={jobNum}
      >
        <Box id={jobNum} sx={imgContainer}>
          {(isHovered || (isSelected === 1)) && <>
            <img id={jobNum} style={{ ...imgStyle, width: '15px', height: '15px', top: '4px', left: '4px' }} src={`skillBG/j${jobNum}.png`} alt="" />

          </>}
        </Box>
        <img id={jobNum} src={`skillBG/job${jobNum}.png`} alt="" />
      </Box>
    </>
  );
};

export default JobHover;

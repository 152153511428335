import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const ItemTypography = ({ label, textStyle, item, theme, statIndex, textValue }) => {

  const SpanTypography = styled(Typography)({
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
    color: theme.palette.tooltip.text,
    lineHeight: 1.5,
  });

  const sLabels =  [
    "MinDamage",                    // 0
    "MaxDamage",                    // 1
    "Movement Speed",                    // 2
    "Movement Speed",                     // 3
    "Max HP",                  // 4
    "Max MP",                  // 5
    "Max CORE",                // 6
    "Max HP",                 // 7   P
    "Max MP",                 // 8   P
    "Max CORE",               // 9   P
    "HP Recovery not in Combat",                  // 10
    "MP Recovery not in Combat",                  // 11
    "CORE Recovery not in Combat",  // 12
    "HP Recovery in Combat",        // 13
    "MP Recovery in Combat",        // 14
    "MP Recovery in Combat",      // 15
    "Min Damage",                   // 16  P
    "Max Damage",                   // 17  P
    "Min Damage",                   // 18  P
    "Max Damage",                   // 19  P
    "Critical Hit",                 // 20  P
    "Critical Hit",              // 21  P
    "Defense",                      // 22
    "Protection",                   // 23  P
    "Magic Def",                    // 24  P
    "Evasion",                      // 25  P
    "Fire Resistance",              // 26  P
    "Lightning Resistance",         // 27  P
    "Cold Resistance",              // 28  P
    "Poison Resistance",            // 29  P
    "Dark Resistance",              // 30  P
    "Elemental Attack",             // 31  P
    "Elemental Attack",             // 32  P
    "HP",                  // 33
    "HP",                           // 34  P
    "MP",                  // 35
    "MP",                           // 36  P
    "CORE Recovery",                // 37
    "CORE Recovery",                // 38  P
    "DeBuffDell",                   // 39
    "Projectile_Speed",             // 40
  ]

  const notPercentage = [ 0,1,2,3,4,5,6,10,11,12,13,14,15,22,33,35,37,39,40 ]
  const restored = [ 33,34,35,36,37,38 ]

  // jobClass = item.dValue[2]
  const jobClass = {
    0	: '', // all class
    1	: 'Gunner',
    2	: 'Sniper',
    4	: 'Ranger',
    8	: 'Magician',
    16	: 'Guardian',
    32	: 'Assassin',
    64	: 'Shaman',
    128	: 'Scout',
    255	: '', //pets
  }

  // itemNameStyles = item.dValue[1]
  const nameStyles = {
    1: { color: theme.palette.text.primary  },
    2: { color: theme.palette.rarity.magic  },
    3: { color: theme.palette.rarity.rare   },
    4: { color: theme.palette.rarity.unique },
    5: { color: theme.palette.rarity.legend },
    default: { color: theme.palette.tooltip.default },
  };


  const itemSort = {
    1: 'Regular Item',
    2: 'Regular Item',
    14: 'Pet',
    15: 'Mount',
    28: 'Key',
    29: 'Token',
    30: 'Consumable',
    31: 'Craft Recipe',
    32: 'Craft Ingredient',
    33: 'Reinforcement Stone',
    37: 'Manual',
    38: 'Pet Outfit',
    39: 'Match Item',
    40: 'Sealed Item',
    41: 'Cash Outfit',
    42: 'Cash Weapon',
    43: 'Spirit Stone',
    44: 'Package',
    45: 'Consumable', // Pandora
    46: 'Consumable', // Inventory
    47: 'Consumable', // Appearance
    48: 'Event Item',
    49: 'Dungeon Items',
  }

  const itemSlot = {
    1: '',
    2: 'Cash Item',
    3: '-',
    4: 'Quest Item',
    
  }
  

  const portals = [ 110200143, 110200144, 110200145, 110200146, 110200147, 110200148, 110200149, 110200150, 110200151, 110200152, 110200153, 110200154, 110210048, 110210049, 110210050, 110210051, 110210052, 110210053, 110210054, 110210055, 110210063, 110210064, 110210065, 110210066, 110210067, 110210068, 110210069, 110210070, 110210071, 110210072, 110210073, 110210074, 110210075, 110210076, 110210077, 110210078, 110210079, 110210080, 110210081, 110210082, 110210083, 110210084, 110210085, 110210086, 110210104, 120210046, 120210047,
  ]

  const awakes = [ 110210033, 110210034, 110210035 ]
  const awakesRange = {
    110210033: 'Range 0.1 Meter',
    110210034: 'Range 0.2 Meter',
    110210035: 'Range 0.3 Meter',
  }

  const vehicle = {
    'LB-Amarance Knight': 170,
    'LB-Amarance Knight II': 170,
    'LB-Crimson': 190,
    'LB-Ezulan': 175,
    'LB-Faust': 150,
    'LB-FaustII': 150,
    'LB-Jonson II': 190,
    'LB-Light': 100,
    'LB-Metal Black II': 180,
    'LB-Pinkfit': 130,
    'LB-Pinkfit [5 Minute Test]': 130,
    'LB-Pinkfit II': 135,
    'LB-Portnoy': 130,
    'LB-Portnoy II': 130,
    'LB-Sparatch': 160,
    'LB-SparatchII': 160,
    'LB-Stra': 115,
    'LB-Various': 140,
    'LB-Various II': 135,
    'LB-Various (Special)': 135,
    '': 180,
  }

  const honor = {
    110200254: 20,
    110200255: 25,
    110200256: 30,
    110200257: 35,
    110200258: 40,
    110200259: 50,
  }

  const getMinutes = (milliseconds) => {
    // Convert milliseconds to seconds
    var totalSeconds = milliseconds / 100;
    // Calculate minutes and remaining seconds
    var minutes = Math.floor(totalSeconds / 60);
    return minutes;
  }

  const getSeconds = (milliseconds) => {
    // Convert milliseconds to seconds
    var totalSeconds = milliseconds / 100;
    // Calculate minutes and remaining seconds
    var seconds = totalSeconds % 60;
    return seconds;
  }

  const getHours = (milliseconds) => {
    // Convert milliseconds to seconds
    var totalSeconds = milliseconds / 100;
    // Calculate minutes and remaining seconds
    var minutes = Math.floor(totalSeconds / 60);
    var hours = 0
    if (minutes >= 60) {
      hours = Math.floor(minutes / 60);
    }
    return hours;
  }


  return (
    <>
      {((label === 'image') && (
          <SpanTypography variant="subtitle1" sx={textStyle}>
            {( item.image > 1568 ? item.image - 1 : item.image )}
          </SpanTypography>
        ))}

      {((label === 'nameStyles') && (
        <SpanTypography sx={(((theme.palette.mode === 'light') && (item.statsArray[1] === 1)) ? ({color: theme.palette.tooltip.label}) : (nameStyles[item.statsArray[1]]))} variant="subtitle1" >
            {item.name}
        </SpanTypography>
        ))}

      {((label === 'code') && (
          <SpanTypography variant="subtitle1" sx={textStyle}>
            {item.code}
          </SpanTypography>
        ))}

      {((label === 'level') && (
          <SpanTypography variant="subtitle1" sx={textStyle}>
            {item.lvlreq > 0 && item.lvlreq}
          </SpanTypography>
        ))}

      
      {((label === 'sort') && (
          <SpanTypography variant="subtitle1" sx={textStyle}>
            {itemSort[item.sort]}
          </SpanTypography>
        ))}

      {((label === 'slot') && (
          <SpanTypography variant="subtitle1" sx={textStyle}>
            {itemSlot[item.statsArray[2]]}
          </SpanTypography>
        ))}

      {((label === 'jobClass') && (
          <SpanTypography variant="subtitle1" sx={textStyle}>
            {jobClass[item.statsArray[6]]}
          </SpanTypography>
        ))}

      {((label === 'filler') && (
          <SpanTypography variant="subtitle1" sx={textStyle}>
          
          </SpanTypography>
        ))}

      {((label === 'reqLv') && (item.code !== 120210043) && (
          <SpanTypography variant="subtitle1" sx={textStyle}>
            Required Lv {textValue}
          </SpanTypography>
        ))}
        
      {((label === 'trade') && (
          <SpanTypography variant="subtitle1" sx={textStyle}>
            {textValue}
          </SpanTypography>
        ))}
      
        {((label === 'usage') && (
            <SpanTypography variant="subtitle1" sx={textStyle}>
              Instant Use
            </SpanTypography>
        ))}

        {((label === 'range') && portals.includes(item.code) && (
            <SpanTypography variant="subtitle1" sx={textStyle}>
              Range 5.0 Meter
            </SpanTypography>
        ))}

        {((label === 'range') && awakes.includes(item.code) && (
            <SpanTypography variant="subtitle1" sx={textStyle}>
              {awakesRange[item.code]}
            </SpanTypography>
        ))}

        {((label === 'cannotrein') && (
            <SpanTypography variant="subtitle1" sx={textStyle}>
            Cannot be reinforced
            </SpanTypography>
          ))}
      
        {((label === 'duration') && (
            <SpanTypography variant="subtitle1" sx={textStyle}>
              Duration {(getHours(item.statsArray[26]) > 0) ? <>{getHours(item.statsArray[26])} hour(s)</> : (getMinutes(item.statsArray[26]) > 0) && <>{getMinutes(item.statsArray[26])} M</>} {(getSeconds(item.statsArray[26]) > 0) && <>{getSeconds(item.statsArray[26])} S</>}
            </SpanTypography>
          ))}

        {((label === 'cd') && (
            <SpanTypography variant="subtitle1" sx={textStyle}>
              Cooldown {(getHours(item.statsArray[24]) > 0) ? <>{getHours(item.statsArray[24])} hour(s)</> : (getMinutes(item.statsArray[24]) > 0) && <>{getMinutes(item.statsArray[24])} M</>} {(getSeconds(item.statsArray[24]) > 0) && <>{getSeconds(item.statsArray[24])} S</>}
            </SpanTypography>
          ))}


        {((label === 'text') && (
            <SpanTypography variant="subtitle1" sx={textStyle}>
              {textValue}
            </SpanTypography>
          ))}
        
        
        {((label === 'sLabels') && (item > 0) && (statIndex !== 39) && (
            <SpanTypography variant="subtitle1" sx={textStyle}>
             {sLabels[statIndex]}
            </SpanTypography>
          ))}

        {((label === 'sLabels') && (item > 0) && (statIndex === 39) && (
            <SpanTypography variant="subtitle1" sx={textStyle}>
             Removes {item} Status Effects
            </SpanTypography>
          ))}

        {((label === 'stat') && (item > 0) && (statIndex !== 39) && (
            <SpanTypography variant="subtitle1" sx={textStyle}>
              {((!notPercentage.includes(statIndex)) && Number.isInteger(item / 100)) && item / 100 }
              {((!notPercentage.includes(statIndex)) && !Number.isInteger(item / 100)) && (item / 100).toFixed(1) }
              {(notPercentage.includes(statIndex)) && item }

              {(restored.includes(statIndex)) && ' Restored' }
              {(!notPercentage.includes(statIndex)) && (!restored.includes(statIndex)) && ' Increased By'}
              {(notPercentage.includes(statIndex)) && (!restored.includes(statIndex)) && ' Increased'}
            </SpanTypography>
          ))}

        
        
          {((label === 'vehicleName') && (
              <SpanTypography variant="subtitle1" sx={textStyle}>Mount Name: <span style={{color: theme.palette.tooltip.label, fontWeight: '300'}}>{item.name}</span></SpanTypography>
          ))}
          {((label === 'vehicleSpeed') && (
              <SpanTypography variant="subtitle1" sx={textStyle}>Movement Speed: <span style={{color: theme.palette.tooltip.label, fontWeight: '300'}}>{vehicle[item.name]}</span></SpanTypography>
          ))}
          {((label === 'gxp') && (
              <SpanTypography variant="subtitle1" sx={textStyle}>Guild EXP +<span style={{color: theme.palette.rarity.magic, fontWeight: '300'}}>{item.code === 110900001 ? 1 : 100}</span></SpanTypography>
          ))}
          {((label === 'gh') && (
              <SpanTypography variant="subtitle1" sx={textStyle}>Guild Honor +<span style={{color: theme.palette.rarity.magic, fontWeight: '300'}}>0</span></SpanTypography>
          ))}
          {((label === 'gsp') && (
              <SpanTypography variant="subtitle1" sx={textStyle}>Guild Skill Points +<span style={{color: theme.palette.rarity.magic, fontWeight: '300'}}>0</span></SpanTypography>
          ))}

          {(label === 'honor1' && <SpanTypography variant="subtitle1" sx={textStyle}>Restore Honor</SpanTypography>)}
          {(label === 'honor2' && <SpanTypography variant="subtitle1" sx={textStyle}>{honor[item.code]} Increased</SpanTypography>)}

          {(label === 'boil' && <SpanTypography variant="subtitle1" sx={textStyle}>Bound Item</SpanTypography>)}
          {(label === 'portal' && <SpanTypography variant="subtitle1" sx={textStyle}>Open a Dimensional Gate</SpanTypography>)}
          {(label === 'skillresetp' && <SpanTypography variant="subtitle1" sx={textStyle}>Skill Initialization Effect</SpanTypography>)}
          {(label === 'skillresett' && <SpanTypography variant="subtitle1" sx={textStyle}>Temporary Skill Initialization Effect</SpanTypography>)}
          {(label === 'sealedancient' && <SpanTypography variant="subtitle1" sx={{...textStyle, pl: 0}}>{item.name.substring(7,item.name.length)}</SpanTypography>)}
          {(label === 'sealedancient45' && <SpanTypography variant="subtitle1" sx={{...textStyle, pl: 0}}>Ezulan's Ring (POINT)</SpanTypography>)}



        {((label === 'desc') && (
            <SpanTypography sx={{...textStyle, lineHeight: 1.35, py: '3px'}}>
              {Object.keys(item.descArray).map((key, index) => (
                item.descArray[key] !== "" && (
                  <React.Fragment key={index}>
                    {item.descArray[key]}<br/>
                  </React.Fragment>
                )))}
            </SpanTypography>
          ))}
    </>

  );
};


export default ItemTypography;



import React, { useState, useEffect } from 'react';
import { Container, Box } from '@mui/material';
import SkillTable from './components/SkillTable';

import jsonItem from './json/allSkillsv4.json';

const SkillPage = ({ changeTheme, theme }) => {
  const [filteredItems, setFilteredItems] = useState([]);

  const fetchItems = async () => {
    try {
      setFilteredItems(jsonItem);
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    changeTheme('dark')
    fetchItems();
  }, []);




  return (
    <Container sx={{ minHeight: '900px', pt: { xs: '5px', md: '20px' }, px: 0, cursor: theme.root.cursor, }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
      </Box>
      <Box sx={{ py: 1, cursor: theme.root.cursor, }}>
        {(
          (filteredItems.length > 0 ? <SkillTable skills={filteredItems} theme={theme} /> : null)
        )}

      </Box>
    </Container>
  );
};

export default SkillPage;

import React, { useState, useEffect, useRef } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow, Paper, Typography, TablePagination, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MobPop from './MobPop';
import MobTypography from './MobTypography';



const MobTable = ({ items, page, setPage, theme }) => {
  const [rowsPerPage, setRowsPerPage] = useState(20); // Number of items per page, defaulting to 10
  const [orderBy, setOrderBy] = useState('code'); // Default sorting by item name
  const [order, setOrder] = useState('asc'); // Default sorting order

  // Create a ref to the selectable text element
  const selectableTextRef = useRef(null);

  // Use useEffect to remove focus on mount
  useEffect(() => {
    if (selectableTextRef.current) {
      selectableTextRef.current.blur();
    }
  }, []); // Empty dependency array ensures the effect runs only once on mount



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
    setPage(0);
  };


  const sortedItems = items.slice().sort((a, b) => {
    const isAsc = order === 'asc';
    if (a[orderBy] < b[orderBy]) {
      return isAsc ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return isAsc ? 1 : -1;
    }
    return 0;
  });


  // Create a custom styled table cell using the styled function
  const CustomTableCell = styled(TableCell)({
    borderBottom: '1px solid rgba(2,2,2,0.5)',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
    padding: '7px 10px',
  });

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      color: theme.palette.tooltip.text,
    },
  }));

  const SpanTypography = styled(Typography)({
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
  });



  const DragonaTablePagination = styled(TablePagination)({
    '& .MuiTablePagination-menuItem ': {
      cursor: theme.root.cursor, // Apply custom cursor style to option items within TablePagination's select dropdown
    },
    '& .MuiTablePagination-actions button': {
      cursor: theme.root.cursor, // Apply custom cursor style (e.g., pointer) to buttons within TablePagination
    },
    '& .MuiTablePagination-select': {
      cursor: theme.root.cursor, // Apply custom cursor style to option items within TablePagination's select dropdown
    },

  });

  const getSortIcon = (property) => {
    if (orderBy === property) {
      return order === 'asc' ? <ArrowUpwardIcon sx={{ fontSize: 11 }} /> : <ArrowDownwardIcon sx={{ fontSize: 11 }} />;
    }
    return null;
  };





  const unselectable = {
    WebkitUserSelect: 'none', /* For Safari */
    MozUserSelect: 'none', /* For Firefox */
    msUserSelect: 'none', /* For Microsoft Edge and Internet Explorer */
    userSelect: 'none' /* Standard syntax */
  }

  const selectable = {
    WebkitUserSelect: 'text', /* For Safari */
    MozUserSelect: 'text', /* For Firefox */
    msUserSelect: 'text',
    userSelect: 'text' /* Standard syntax */
  }

  const labelStyle = {
    userSelect: 'none'
  }




  return (
    <>










      <TableContainer component={Paper} sx={{ ...unselectable, p: 0 }}>
        <Table >
          <TableHead >
            <TableRow sx={{ backgroundColor: theme.palette.table.headerbg, gap: theme.spacing(0) }}>
              <CustomTableCell sx={{ width: { xs: '55px', sm: '55px', md: '65px', lg: '65px' } }} onClick={() => handleSortRequest('mobLv')}>
                <SpanTypography variant="subtitle1" >LvL {getSortIcon('mobLv')}</SpanTypography>
              </CustomTableCell>

              <CustomTableCell sx={{ width: { xs: 'auto', sm: 'auto', md: '200px', lg: '300px' } }} onClick={() => handleSortRequest('mobHp')}>
                <SpanTypography variant="subtitle1" >Monster {getSortIcon('mobHp')}</SpanTypography>
              </CustomTableCell>

              <Hidden smDown>
                <CustomTableCell sx={{ width: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' } }} onClick={() => handleSortRequest('job')}>
                  <SpanTypography variant="subtitle1" >Job {getSortIcon('job')}</SpanTypography>
                </CustomTableCell>
              </Hidden>

              <Hidden mdDown>
                <CustomTableCell sx={{ width: { xs: 'auto', sm: 'auto', md: '75px', lg: '75px' } }} >
                  <SpanTypography variant="subtitle1" >Map </SpanTypography>
                </CustomTableCell>
              </Hidden>

              <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '80px', lg: '80px' } }} onClick={() => handleSortRequest('hp')}>
                <SpanTypography variant="subtitle1" >HP {getSortIcon('hp')}</SpanTypography>
              </CustomTableCell>

              <Hidden smDown>
                <CustomTableCell sx={{ width: { xs: '60px', sm: '70px', md: '70px', lg: '70px' } }} onClick={() => handleSortRequest('mp')}>
                  <SpanTypography variant="subtitle1" >MP {getSortIcon('mp')}</SpanTypography>
                </CustomTableCell>
              </Hidden>

              <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '80px', lg: '80px' } }} onClick={() => handleSortRequest('xp')}>
                <SpanTypography variant="subtitle1" >EXP {getSortIcon('xp')}</SpanTypography>
              </CustomTableCell>

              <Hidden smDown>
                <CustomTableCell sx={{ width: { xs: '105px', sm: '105px', md: '105px', lg: '105px' } }} onClick={() => handleSortRequest('code')}>
                  <SpanTypography variant="subtitle1" >Code {getSortIcon('code')}</SpanTypography>
                </CustomTableCell>
              </Hidden>
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : sortedItems
            ).map(item => (
              <React.Fragment key={item.code}>

                <TableRow sx={{ gap: theme.spacing(0), }}>
                  <HtmlTooltip
                    title={
                      <>
                        <MobPop item={item} theme={theme} ></MobPop>
                      </>
                    }
                    placement="right-start" >

                    <CustomTableCell sx={{ width: { xs: '55px', sm: '55px', md: '65px', lg: '65px' } }} >
                      <MobTypography label={'level'} textStyle={labelStyle} item={item} theme={theme} />
                    </CustomTableCell>
                  </HtmlTooltip>


                  <CustomTableCell sx={{ width: { xs: 'auto', sm: 'auto', md: '200px', lg: '300px' } }} >
                    <MobTypography label={'name'} textStyle={labelStyle} item={item} theme={theme} />
                  </CustomTableCell>

                  <Hidden smDown>
                    <CustomTableCell sx={{ width: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' } }} >
                      <MobTypography label={'job'} textStyle={labelStyle} item={item} theme={theme} />
                    </CustomTableCell>
                  </Hidden>

                  <Hidden mdDown>
                    <CustomTableCell sx={{ width: { xs: 'auto', sm: 'auto', md: '75px', lg: '75px' } }} >
                      <MobTypography label={'map'} textStyle={labelStyle} item={item} theme={theme} />
                    </CustomTableCell>
                  </Hidden>


                  <CustomTableCell sx={{ width: { xs: '60px', sm: '80px', md: '80px', lg: '80px' } }} >
                    <MobTypography label={'hp'} textStyle={labelStyle} item={item} theme={theme} />
                  </CustomTableCell>

                  <Hidden smDown>
                    <CustomTableCell sx={{ width: { xs: '60px', sm: '70px', md: '70px', lg: '70px' } }} >
                      <MobTypography label={'mp'} textStyle={labelStyle} item={item} theme={theme} />
                    </CustomTableCell>
                  </Hidden>

                  <CustomTableCell sx={{ width: { xs: '60px', sm: '80px', md: '80px', lg: '80px' } }} >
                    <MobTypography label={'xp'} textStyle={labelStyle} item={item} theme={theme} />
                  </CustomTableCell>

                  <Hidden smDown>
                    <CustomTableCell ref={selectableTextRef} sx={{ ...selectable, width: { xs: '105px', sm: '105px', md: '105px', lg: '105px' } }} >
                      <MobTypography label={'code'} textStyle={selectable} item={item} theme={theme} />
                    </CustomTableCell>
                  </Hidden>

                </TableRow>

              </React.Fragment>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
      <DragonaTablePagination
        rowsPerPageOptions={[8, 10, 20, 30, 50]} // Options for items per page
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}

      />


    </>

  );
}

export default MobTable;

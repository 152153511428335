import React, { useState, useEffect } from 'react';
import { Container, Box, InputLabel, MenuItem, FormControl, Select, TextField } from '@mui/material';
import ItemTable from './components/ItemTable';
import EmptyTable from './components/EmptyTable';

import jsonItem from './json/dragona_main_db.items.json';


const ItemPage = ({ theme }) => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [page, setPage] = useState(0);

  const [mainFilter1, setMainFilter1] = useState('');
  const [mainFilter2, setMainFilter2] = useState('');
  const [searchFilter, setSearchFilter] = useState('');


  // Filter change handler 
  const handleChange1 = (event) => {
    setMainFilter1(event.target.value);
    setPage(0);
    setSearchFilter('');

    // load all unfiltered items
    let scoutItems = items

    if (mainFilter2 !== '') {
      scoutItems = scoutItems.filter(item => (item.statsArray[1] === mainFilter2));
    }

    // filter using item.sort value
    if (event.target.value !== 0 && event.target.value !== '') {
      if (event.target.value === 2) {
        scoutItems = scoutItems.filter(item => ((item.sort === 1) || (item.sort === 2)));
      } else {
        scoutItems = scoutItems.filter(item => (item.sort === event.target.value));
      }
    }

    setFilteredItems(scoutItems);
  };


  const handleChange2 = (event) => {
    setMainFilter2(event.target.value);
    setPage(0);
    setSearchFilter('');

    // load all unfiltered items
    let scoutItems = items

    if ((mainFilter1 !== '') && (mainFilter1 !== 0)) {
      scoutItems = scoutItems.filter(item => (item.sort === mainFilter1));
    }

    scoutItems = scoutItems.filter(item => (item.statsArray[1] === event.target.value));  

    setFilteredItems(scoutItems);
  };

  const handleSearch = (event) => {
    setPage(0);
    setMainFilter1(0);
    setMainFilter2('');

    // Remove non-alphanumeric characters using regular expression
    const stringWithoutSpecialChars = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '');


    setSearchFilter(stringWithoutSpecialChars)

    // load all unfiltered items
    let scoutItems = items

    try {
      const wordToCheck = stringWithoutSpecialChars;
      const regex = new RegExp(wordToCheck, 'i'); // 'i' flag for case-insensitive matching
      scoutItems = scoutItems.filter(obj => regex.test(obj.name));
  
    } catch (error) {
      
    }


    setFilteredItems(scoutItems);
  }



  const fetchItems = async () => {
    try {
      // Original item data
      // const originalResponse = await axios.get('https://db.insideezulan.online/api/equipment'); // Change localhost to your IP
      // const originalResponse = await axios.get('http://192.168.254.105:3001/api/items'); // Change localhost to your IP

      // originalResponse.data.map
      const modifiedResponse = jsonItem.map(item => {
        const statsArray = item.stats.split('-').map(Number); // Split ac by '-' and convert each part to a number
        const newText = item.hasOwnProperty('desc') ? item.desc : ' '
        const descArray = newText.split('\n');

        let str = String(item.image);

        // Split the string into two parts
        let ic1 = Number(str.slice(0, Math.floor(str.length / 2))); // Take the first half
        let ic2 = Number(str.slice(Math.floor(str.length / 2))); // Take the second half

        return {
          ...item,
          statsArray, descArray, ic1, ic2, // Store the array of divided numbers in a new property
        };
      });

      setItems(modifiedResponse);
      setFilteredItems(modifiedResponse);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);






  return (
    <Container sx={{ pt: {xs: '5px', md: '20px'}, px: 0, cursor: theme.root.cursor,}}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <FormControl sx={{width: '40%'}}>
          <InputLabel id="main-filter-select-label1">Main Filter</InputLabel>
            <Select
              labelId="main-filter-label1"
              id="main-filter-select1"
              value={mainFilter1}
              label="Main Filter"
              onChange={handleChange1}
            >
            <MenuItem value={0}>All</MenuItem>
            <MenuItem value={2}>Regular Item</MenuItem>
            <MenuItem value={14}>Pet</MenuItem>
            <MenuItem value={15}>Mount</MenuItem>
            <MenuItem value={28}>Key</MenuItem>
            <MenuItem value={29}>Token</MenuItem>
            <MenuItem value={30}>Consumables</MenuItem>
            <MenuItem value={31}>Schemes</MenuItem>
            <MenuItem value={32}>Materials</MenuItem>
            <MenuItem value={33}>Reinforcement Stone</MenuItem>
            <MenuItem value={37}>Pet Manual</MenuItem>
            <MenuItem value={38}>Pet Outfit</MenuItem>
            <MenuItem value={39}>Match Items</MenuItem>
            <MenuItem value={40}>Sealed Item</MenuItem>
            <MenuItem value={41}>Cash Costume</MenuItem>
            <MenuItem value={42}>Cash Weapon</MenuItem>
            <MenuItem value={44}>Package</MenuItem>
            <MenuItem value={45}>Lucky Dip</MenuItem>
            <MenuItem value={46}>Inventory Item</MenuItem>
            <MenuItem value={47}>Appearance</MenuItem>
            <MenuItem value={48}>Stock Securities</MenuItem>
            <MenuItem value={49}>Dungeon Items</MenuItem>

          </Select>
        </FormControl>
        <FormControl sx={{width: '40%'}}>
          <InputLabel id="main-filter-select-label2">Sub Filter</InputLabel>
          <Select
            labelId="main-filter-label2"
            id="main-filter-select2"
            value={mainFilter2}
            label="Sub Filter"
            onChange={handleChange2}
          >
            <MenuItem value={1}>Common</MenuItem>
            <MenuItem value={2}>Magic</MenuItem>
            <MenuItem value={3}>Rare</MenuItem>
            <MenuItem value={4}>Unique</MenuItem>
          </Select>
        </FormControl>
        <div sx={{width: 'auto'}} >
          <TextField id="outlined-search" label="Search field" value={searchFilter} type="search" onChange={handleSearch}/>
        </div>
      </Box>
      <Box sx={{ py: 1, cursor: theme.root.cursor,}}>
        {(
        (filteredItems.length > 0 ? <ItemTable items={filteredItems} theme={theme} page={page} setPage={setPage} /> : <EmptyTable theme={theme} />)
        )}
        
      </Box>
    </Container>
  );
};

export default ItemPage;

import React, { useState, useEffect, useRef } from 'react';
import { Box, Table, TableContainer, TableHead, TableBody, TableCell, TableRow, Paper, Typography, TablePagination, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ItemPop from './ItemPop';
import ItemTypography from './ItemTypography';


import Draggable from 'react-draggable';
import closeIcon from '../assets/close2.png';




const ItemTable = ({ items, page, setPage, theme }) => {
  const [rowsPerPage, setRowsPerPage] = useState(8); // Number of items per page, defaulting to 10
  const [orderBy, setOrderBy] = useState('name'); // Default sorting by item name
  const [order, setOrder] = useState('asc'); // Default sorting order

  const [isVisible, setIsVisible] = useState(false);
  const [clickedItem, setClickedItem] = useState({});
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // Create a ref to the selectable text element
  const selectableTextRef = useRef(null);

  // Use useEffect to remove focus on mount
  useEffect(() => {
    if (selectableTextRef.current) {
      selectableTextRef.current.blur();
    }
  }, []); // Empty dependency array ensures the effect runs only once on mount



  const handleShow = (selectedItem, e) => {

    setClickedItem(selectedItem)
    setPosition({ x: e.clientX, y: e.clientY });
    setIsVisible(true);
  };

  const handleClose = (e) => {
    setIsVisible(false);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
    setPage(0);
  };


  const sortedItems = items.slice().sort((a, b) => {
    const isAsc = order === 'asc';
    if (a[orderBy] < b[orderBy]) {
      return isAsc ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return isAsc ? 1 : -1;
    }
    return 0;
  });


  // Create a custom styled table cell using the styled function
  const CustomTableCell = styled(TableCell)({
    borderBottom: '1px solid rgba(2,2,2,0.5)',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
  });

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      color: theme.palette.tooltip.text,
    },
  }));

  const SpanTypography = styled(Typography)({
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
  });



  const DragonaTablePagination = styled(TablePagination)({
    '& .MuiTablePagination-menuItem ': {
      cursor: theme.root.cursor, // Apply custom cursor style to option items within TablePagination's select dropdown
    },
    '& .MuiTablePagination-actions button': {
      cursor: theme.root.cursor, // Apply custom cursor style (e.g., pointer) to buttons within TablePagination
    },
    '& .MuiTablePagination-select': {
      cursor: theme.root.cursor, // Apply custom cursor style to option items within TablePagination's select dropdown
    },

  });

  const getSortIcon = (property) => {
    if (orderBy === property) {
      return order === 'asc' ? <ArrowUpwardIcon sx={{ fontSize: 11 }} /> : <ArrowDownwardIcon sx={{ fontSize: 11 }} />;
    }
    return null;
  };




  const styles = {
    container: {
      backgroundColor: 'transparent',
      padding: '0px',
      position: 'absolute',
      zIndex: 900,
      transform: 'translate(-50%, -50%)',
      color: theme.palette.tooltip.text,
      cursor: theme.root.handCursor
    },
    closeButton: {
      position: 'absolute',
      top: '9px',
      right: '10px',
      cursor: theme.root.cursor
    },
  };

  const imgContainer = {
    position: 'relative',
    left: '8px',
    width: '36px',
    height: '36px',
    border: "1px solid rgba(2,2,2,.1)",
    borderRadius: '5px',
    backgroundColor: theme.palette.tooltip.icon,
  }

  const containerImg = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }

  const unselectable = {
    WebkitUserSelect: 'none', /* For Safari */
    MozUserSelect: 'none', /* For Firefox */
    msUserSelect: 'none', /* For Microsoft Edge and Internet Explorer */
    userSelect: 'none' /* Standard syntax */
  }

  const selectable = {
    WebkitUserSelect: 'text', /* For Safari */
    MozUserSelect: 'text', /* For Firefox */
    msUserSelect: 'text',
    userSelect: 'text' /* Standard syntax */
  }

  function checkIfStringContainsWord(str, wordToCheck) {
    return str.includes(wordToCheck);
  }

  return (
    <>


      <>
        {isVisible && (
          <Draggable defaultPosition={{ x: 0, y: 0 }} >
            <Box style={{ ...unselectable, ...styles.container, left: position.x, top: position.y }}>
              <img alt='Dragona Item Info Popup' src={closeIcon} style={styles.closeButton} onClick={(e) => handleClose(e)} onTouchStart={(e) => handleClose(e)} />
              <ItemPop item={clickedItem} theme={theme} ></ItemPop>
            </Box>
          </Draggable>
        )}
      </>









      <TableContainer component={Paper} sx={{ ...unselectable, p: 0 }}>
        <Table >
          <TableHead >
            <TableRow sx={{ backgroundColor: theme.palette.table.headerbg }}>
              <CustomTableCell sx={{ pl: '11px', width: { xs: '40px', sm: '40px', md: '40px', lg: '40px' }, cursor: theme.root.sCursor }} >
                <SpanTypography variant="subtitle1" sx={{ cursor: theme.root.sCursor }}>Icon</SpanTypography>
              </CustomTableCell>

              <CustomTableCell sx={{ width: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' } }} onClick={() => handleSortRequest('name')}>
                <SpanTypography variant="subtitle1" >Item Name {getSortIcon('name')}</SpanTypography>
              </CustomTableCell>

              <Hidden smDown>
                <CustomTableCell sx={{ width: { xs: '85px', sm: '85px', md: '95px', lg: '100px' } }} onClick={() => handleSortRequest('lvlreq')}>
                  <SpanTypography variant="subtitle1" >Required Lv {getSortIcon('lvlreq')}</SpanTypography>
                </CustomTableCell>
              </Hidden>

              <CustomTableCell sx={{ width: { xs: '105px', sm: '105px', md: '105px', lg: '120px' } }} onClick={() => handleSortRequest('code')}>
                <SpanTypography variant="subtitle1" >Item Code {getSortIcon('code')}</SpanTypography>
              </CustomTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : sortedItems
            ).map(item => (
              <React.Fragment key={item.code}>

                <TableRow >
                  <HtmlTooltip
                    title={
                      <>
                        <ItemPop item={item} theme={theme} ></ItemPop>
                      </>
                    }
                    placement="right-start" >

                    <CustomTableCell sx={{ p: 0, paddingTop: '4px', width: { xs: '40px', sm: '40px', md: '40px', lg: '40px' } }} onClick={(e) => handleShow(item, e)}>
                      <div style={imgContainer}>

                        {((item.ic2 - 1 > 1568) && (item.sort !== 31 && item.sort !== 32 && item.sort !== 39 && item.sort !== 49)) && (
                          <img style={containerImg} src={`images/fullset01/${item.ic2 - 1}.png`} alt={item.name} />
                        )}

                        {((item.ic2 - 1 < 1568) && (item.sort !== 31 && item.sort !== 32 && item.sort !== 39 && item.sort !== 49)) && (
                          <img style={containerImg} src={`images/fullset01/${item.ic2}.png`} alt={item.name} />
                        )}

                        {(item.code >= 110700041) && (item.code <= 110700046) && (
                          <img style={containerImg} src={`images/fullset01/${item.ic2}.png`} alt={item.name} />
                        )}


                        {(item.sort === 31 || item.sort === 32) && item.code > 110700046 && (
                          <img style={containerImg} src={`images/full01/${item.ic2}.png`} alt={item.name} />
                        )}

                        {(item.sort === 39) && (
                          <img style={containerImg} src={`images/mat04/${item.ic2}.png`} alt={item.name} />
                        )}

                        {(item.sort === 49) && (
                          <img style={containerImg} src={`images/dun04/${item.ic2}.png`} alt={item.name} />
                        )}

                        {(item.sort === 14) && (item.statsArray[1] > 3) && (
                          <img style={containerImg} src={`images/overlay/${Number(item.statsArray[1])}.png`} alt={item.name} />
                        )}

                        {(item.code >= 110700041) && (item.code <= 110700046) && (
                          <img style={containerImg} src={`images/fullset01/${item.ic2}.png`} alt={item.name} />
                        )}

                        {((item.sort === 40) && (checkIfStringContainsWord(item.name, 'Scheme'))) && (
                          <img style={containerImg} src={`images/full01/${item.ic2}.png`} alt={item.name} />
                        )}

                        {(item.sort === 40) && (
                          <img style={containerImg} src={`images/overlay/sealed.png`} alt={item.name} />
                        )}

                      </div>
                    </CustomTableCell>
                  </HtmlTooltip>


                  <CustomTableCell sx={{ width: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' } }} onClick={(e) => handleShow(item, e)} >
                    <ItemTypography label={'nameStyles'} textSection={'tableRow'} textStyle={{ selectable }} item={item} theme={theme} />
                  </CustomTableCell>

                  <Hidden smDown>
                    <CustomTableCell sx={{ width: { sm: '0px', md: '120px', lg: '120px' } }}  >
                      <ItemTypography label={'level'} textStyle={{ selectable }} item={item} theme={theme} />
                    </CustomTableCell>
                  </Hidden>

                  <CustomTableCell ref={selectableTextRef} sx={{ ...selectable, width: { xs: '105px', sm: '105px', md: '105px', lg: '120px' } }} >
                    <ItemTypography label={'code'} textStyle={{ ...selectable, color: theme.palette.tooltip.row }} item={item} theme={theme} />
                  </CustomTableCell>

                </TableRow>

              </React.Fragment>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
      <DragonaTablePagination
        rowsPerPageOptions={[8, 10, 20, 30, 50]} // Options for items per page
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}

      />






    </>

  );
}

export default ItemTable;

import React, { useState, useEffect, useRef } from 'react';
import { Box, Table, TableContainer, TableHead, TableBody, TableCell, TableRow, Paper, Typography, TablePagination, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EquipPop from './EquipPop';
import EquipTypography from './EquipTypography';

import Draggable from 'react-draggable';

import closeIcon from '../assets/close2.png';




const DataTable = ({ items, page, setPage, theme }) => {

  const [rowsPerPage, setRowsPerPage] = useState(8); // Number of items per page, defaulting to 10
  const [orderBy, setOrderBy] = useState('name'); // Default sorting by item name
  const [order, setOrder] = useState('asc'); // Default sorting order

  const [isVisible, setIsVisible] = useState(false);
  const [clickedItem, setClickedItem] = useState({});
  const [position, setPosition] = useState({x: 0, y:0});
  const [isVisible2, setIsVisible2] = useState(false);
  const [clickedItem2, setClickedItem2] = useState({});
  const [position2, setPosition2] = useState({x: 0, y:0});

      // Create a ref to the selectable text element
      const selectableTextRef = useRef(null);

      // Use useEffect to remove focus on mount
      useEffect(() => {
        if (selectableTextRef.current) {
          selectableTextRef.current.blur();
        }
      }, []); // Empty dependency array ensures the effect runs only once on mount
    
  

  const handleShow = (selectedItem, e) => {
    
    if (isVisible === false) {
      setClickedItem(selectedItem)
      setPosition({ x: e.clientX, y: e.clientY });
      setIsVisible(true);
    } else {
      setClickedItem2(selectedItem)
      setPosition2({ x: e.clientX, y: e.clientY });
      setIsVisible2(true);
    }
  };

  const handleClose = (e) => {
      setIsVisible(false);
      setIsVisible2(false);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
    setPage(0);
  };


   const sortedItems = items.slice().sort((a, b) => {
        const isAsc = order === 'asc';
        if (a[orderBy] < b[orderBy]) {
          return isAsc ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
          return isAsc ? 1 : -1;
        }
        return 0;
      });


    // Create a custom styled table cell using the styled function
    const CustomTableCell = styled(TableCell)({
        borderBottom: '1px solid rgba(2,2,2,0.5)',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        cursor: theme.root.cursor,
    });

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: 'transparent',
          color: theme.palette.tooltip.text,
        },
      }));

    const SpanTypography = styled(Typography)({
      fontSize: theme.typography.fontSize,
      cursor: theme.root.cursor,
    });



    const DragonaTablePagination = styled(TablePagination)({
        '& .MuiTablePagination-menuItem ': {
          cursor: theme.root.cursor, // Apply custom cursor style to option items within TablePagination's select dropdown
        },
        '& .MuiTablePagination-actions button': {
          cursor: theme.root.cursor, // Apply custom cursor style (e.g., pointer) to buttons within TablePagination
        },
        '& .MuiTablePagination-select': {
          cursor: theme.root.cursor, // Apply custom cursor style to option items within TablePagination's select dropdown
        },

      });

  const getSortIcon = (property) => {
    if (orderBy === property) {
      return order === 'asc' ? <ArrowUpwardIcon sx={{fontSize: 11}} /> : <ArrowDownwardIcon sx={{fontSize: 11}} />;
    }
    return null;
  };




  const styles = {
    container: {
      backgroundColor: 'transparent',
      padding: '0px',
      position: 'absolute',
      zIndex: 900,
      transform: 'translate(-50%, -50%)',
      color: theme.palette.tooltip.text,
      cursor: theme.root.handCursor
  },
  closeButton: {
      position: 'absolute',
      top: '9px',
      right: '10px',
      cursor: theme.root.cursor
  },
};

 const imgContainer = {
  position: 'relative',
  left: '8px',
  width: '36px', 
  height: '36px',
  border:"1px solid rgba(2,2,2,.1)",
  borderRadius: '5px',
  backgroundColor: theme.palette.tooltip.icon,
}

const containerImg = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}

const unselectable = {
  WebkitUserSelect: 'none', /* For Safari */
  MozUserSelect: 'none', /* For Firefox */
  msUserSelect: 'none', /* For Microsoft Edge and Internet Explorer */
  userSelect: 'none' /* Standard syntax */
}

const selectable = {
  WebkitUserSelect: 'text', /* For Safari */
  MozUserSelect: 'text', /* For Firefox */
  msUserSelect: 'text',
  userSelect: 'text' /* Standard syntax */
}

  return (
    <>




          <>
            {isVisible && (
                <Draggable defaultPosition={{ x: 0, y: 0 }} >
                    <Box style={{...unselectable,  ...styles.container, left: position.x, top: position.y }}>
                        <img alt='Dragona Item Info Popup' src={closeIcon} style={styles.closeButton} onClick={(e) => handleClose(e)} onTouchStart={(e) => handleClose(e)}/>
                        <EquipPop item={clickedItem} theme={theme} ></EquipPop>
                    </Box>
                </Draggable>
            )}

            {isVisible2 && (
                <Draggable defaultPosition={{ x: 0, y: 0 }} >
                    <Box style={{...unselectable,  ...styles.container, left: position2.x, top: position2.y }}>
                        <img alt='Dragona Item Info Popup' src={closeIcon} style={styles.closeButton} sx={{top: {xs: '7px'}}} onClick={(e) => handleClose(e)} onTouchStart={(e) => handleClose(e)}/>
                        <EquipPop item={clickedItem2} theme={theme} ></EquipPop>
                    </Box>
                </Draggable>
            )}
          </>









         <TableContainer component={Paper} sx={{...unselectable, p: 0 }}>
            <Table >
              <TableHead >
                <TableRow sx={{ backgroundColor: theme.palette.table.headerbg}}>

                   <CustomTableCell sx={{width: '60px', cursor: theme.root.sCursor}} >
                        <SpanTypography variant="subtitle1" sx={{cursor: theme.root.sCursor}}>Icon</SpanTypography>
                    </CustomTableCell>

                        <CustomTableCell sx={{width: { xs: 'auto', sm: 'auto', md: '300px', lg: '400px' }}} onClick={() => handleSortRequest('name')}>
                            <SpanTypography variant="subtitle1" >Item Name {getSortIcon('name')}</SpanTypography>
                        </CustomTableCell>

                    <Hidden lgDown>
                        <CustomTableCell sx={{width: { xs: '0px', sm: '0px', md: '115px', lg: '115px' }}} onClick={() => handleSortRequest('code')} >
                            <SpanTypography variant="subtitle1" >Item Code {getSortIcon('code')}</SpanTypography>
                        </CustomTableCell>
                    </Hidden>

                    <Hidden smDown>
                        <CustomTableCell sx={{width: { xs: '0px', sm: '0px', md: '70px', lg: '100px', cursor: theme.root.sCursor }}} >
                            <SpanTypography variant="subtitle1" sx={{cursor: theme.root.sCursor}} >Class </SpanTypography>
                        </CustomTableCell>
                    </Hidden>
                    <CustomTableCell sx={{width: { xs: '80px', sm: '70px', md: '70px', lg: '90px' }}} onClick={() => handleSortRequest('value')}>
                        <SpanTypography variant="subtitle1" >Level {getSortIcon('value')}</SpanTypography>
                    </CustomTableCell>
                    <Hidden smDown>
                        <CustomTableCell sx={{width: { xs: '0px', sm: '0px', md: '70px', lg: '110px' }}} onClick={() => handleSortRequest('sortb')}>
                            <SpanTypography variant="subtitle1" >Attack {getSortIcon('sortb')}</SpanTypography>
                        </CustomTableCell>
                    </Hidden>
                    <Hidden smDown>
                        <CustomTableCell sx={{width: { xs: '0px', sm: '0px', md: '70px', lg: '110px' }}} onClick={() => handleSortRequest('sortc')}>
                            <SpanTypography variant="subtitle1" >Defense {getSortIcon('sortc')}</SpanTypography>
                        </CustomTableCell>
                    </Hidden>
                    <Hidden smDown>
                        <CustomTableCell sx={{width: { xs: '0px', sm: '0px', md: '0px', lg: '190px' }}} onClick={() => handleSortRequest('sortd')}>
                            <SpanTypography variant="subtitle1" >Type {getSortIcon('sortd')}</SpanTypography>
                        </CustomTableCell>
                    </Hidden>
                </TableRow>
              </TableHead>
              
              <TableBody>
                {(rowsPerPage > 0
                  ? sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : sortedItems
                ).map(item => (
                  <React.Fragment key={item.code}>


                    <TableRow >
                                  
                      <HtmlTooltip 
                        title={
                          <>
                            <EquipPop item={item} theme={theme} ></EquipPop>
                          </>
                         }
                        placement="right-start" >

                          <CustomTableCell sx={{p: 0, paddingTop: '4px', textAlign: 'center', width: '60px'}} onClick={(e) => handleShow(item, e)}>
                            <div style={imgContainer}>

                            {( ((item.dValue[0] === 145)) ?
                                <img style={containerImg} src={`images/fullset01/2131.png`} alt={item.name} />
                                :
                                <img style={containerImg} src={`images/fullset01/${Number(String(item.ic).substring(String(item.ic).length - 4))}.png`} alt={item.name} />
                              )}

                            {( (Number(item.dValue[1]) >=2) && (Number(item.dValue[1]) <=4) && ((item.dValue[0] !== 145)) &&
                                <img style={containerImg} src={`images/overlay/${Number(item.dValue[1])}.png`} alt={item.name} />
                              )}
                            </div>
                          </CustomTableCell>

                      </HtmlTooltip>

                      <CustomTableCell sx={{width: 'auto'}} onClick={(e) => handleShow(item, e)} >
                        <EquipTypography label={'itemNameStyles'} textSection={'tableRow'} item={item} theme={theme} />
                      </CustomTableCell>

                      <Hidden lgDown>
                        <CustomTableCell ref={selectableTextRef} sx={{...selectable, width: { xs: '0px', sm: '0px', md: '115px', lg: '115px' }}} >
                          <EquipTypography label={'code'} textStyle={{color: theme.palette.tooltip.row}}  item={item} theme={theme} />
                        </CustomTableCell>
                      </Hidden>
                    
                      <Hidden smDown>
                        <CustomTableCell sx={{width: { xs: '80px', sm: '80px', md: '90px', lg: '100px' }}} >
                          <EquipTypography label={'jobClass'} textStyle={{color: theme.palette.tooltip.row}} item={item} theme={theme} />
                        </CustomTableCell>
                      </Hidden>

                      <CustomTableCell sx={{width: { xs: '80px', sm: '80px', md: '80px', lg: '90px' }}} >
                        <EquipTypography label={'level'}  textStyle={{color: theme.palette.tooltip.row}}   item={item} theme={theme} />
                      </CustomTableCell>

                      <Hidden smDown>
                        <CustomTableCell sx={{width: { xs: '80px', sm: '80px', md: '90px', lg: '110px' }}} >
                        {(item.dValue[5] !== 0 && (
                                <EquipTypography label='noLabel' textStyle={{color: theme.palette.tooltip.atk}} theme={theme} item={`${item.dValue[5]} ~ ${item.dValue[6]}`}/>
                            ))}
                        </CustomTableCell>
                      </Hidden>

                      <Hidden smDown>
                        <CustomTableCell sx={{width: { xs: '80px', sm: '80px', md: '90px', lg: '110px' }}} >
                        {(item.dValue[23] !== 0 && (
                                <EquipTypography textStyle={{ color: theme.palette.rarity.magic }} label='noLabel' textSection={'tableRow'} item={`${item.dValue[23]} Increased`} theme={theme} />
                            ))}
                        </CustomTableCell>
                      </Hidden>

                      <Hidden smDown>
                        <CustomTableCell sx={{width: { xs: '80px', sm: '80px', md: '90px', lg: '190px' }}} >
                          <EquipTypography label={'itemType'} textStyle={{color: theme.palette.tooltip.row}}  textSection={'tableRow'} item={item} theme={theme} />
                        </CustomTableCell>
                      </Hidden>

                    </TableRow>

                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
          <DragonaTablePagination
            rowsPerPageOptions={[ 8, 10, 20, 30, 50]} // Options for items per page
            component="div"
            count={items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            
          />
      </>

  );
}

export default DataTable;

import React from 'react';
import { Box } from '@mui/material';

import bgaa from '../assets/aa.png';
import bgab from '../assets/ab.png';
import bgac from '../assets/ac.png';
import bgba from '../assets/ba.png';
import bgbc from '../assets/bc.png';
import bgca from '../assets/ca.png';
import bgcb from '../assets/cb.png';
import bgcc from '../assets/cc.png';


const MobPop = ({ item, theme }) => {


  return (
    <>
      <Box sx={{display: 'flex', flexWrap: 'wrap', width: '300px', height:'auto' , backgroundColor: theme.palette.tooltip.bg, border:"1px solid rgba(2,2,2,.02)", borderRadius: '3px'}}>

        <Box sx={{ backgroundImage: `url(${bgaa})`, width: '5px', height: '3px',  }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgab})`, width: '288px', height: '3px', backgroundSize: '100% 100%',}} ></Box>
        <Box sx={{ backgroundImage: `url(${bgac})`, width: '5px', height: '3px'  }} ></Box>

        <Box sx={{ backgroundImage: `url(${bgba})`, width: '3px', minHeight: '5px', backgroundSize: '100% 100%',}} ></Box>
        <Box sx={{ width: '292px', minHeight: '5px', gap: theme.spacing(0),}} >

 

        </Box>
        <Box sx={{ backgroundImage: `url(${bgbc})`, width: '3px', minHeight: '5px', backgroundSize: '100% 100%',}} ></Box>

        <Box sx={{ backgroundImage: `url(${bgca})`, width: '5px', height: '3px' }} ></Box>
        <Box sx={{ backgroundImage: `url(${bgcb})`, width: '288px', height: '3px', backgroundSize: '100% 100%',}} ></Box>
        <Box sx={{ backgroundImage: `url(${bgcc})`, width: '5px', height: '3px'  }} ></Box>
      </Box>
    </>   

  );  
}



export default MobPop;


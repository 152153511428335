import React, { useState } from 'react';
import '../css/ImageHover.css'; // Import CSS for styling
import { Typography } from '@mui/material';

const ImageHover = ({ btnId, plusDisabled, allDisabled, skCurLv }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  const imgContainer = {
    position: 'relative',
    top: '3px',
    width: '11px',
    height: '10px',
  }



  const imgStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }

  const plus = (x) => {
    const myString = x;
    const myNumber = +myString;

    return (myNumber + 1000).toString();
  }

  const minus = (x) => {
    const myString = x;
    const myNumber = +myString;

    return (myNumber + 2000).toString();
  }


  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleMouseDown = () => setIsHovered(false);
  const handleMouseUp = () => setIsHovered(true);
  const handleTouchStart = () => setIsHovered(false);
  const handleTouchEnd = () => setIsHovered(false);

  const handleMouseEnter2 = () => setIsHovered2(true);
  const handleMouseLeave2 = () => setIsHovered2(false);
  const handleMouseDown2 = () => setIsHovered2(false);
  const handleMouseUp2 = () => setIsHovered2(true);
  const handleTouchStart2 = () => setIsHovered2(false);
  const handleTouchEnd2 = () => setIsHovered2(false);

  const hoverTrue = () => setIsHovered3(true);
  const hoverFalse = () => setIsHovered3(false);


  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ ...imgContainer, left: '3px' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {!plusDisabled && !allDisabled ? (!isHovered ? <img id={plus(btnId)} src="/skillBG/a1.png" alt="Plus" style={imgStyle} /> : <img id={plus(btnId)} src="/skillBG/b1.png" alt="Plus" style={imgStyle} />)
            : <img id={plus(btnId)} src="/skillBG/d1.png" alt="Plus" style={imgStyle} />}
        </div>
        <div style={{ ...imgContainer, left: '10px' }}
          onMouseEnter={handleMouseEnter2}
          onMouseLeave={handleMouseLeave2}
          onMouseDown={handleMouseDown2}
          onMouseUp={handleMouseUp2}
          onTouchStart={handleTouchStart2}
          onTouchEnd={handleTouchEnd2}
        >
          {(skCurLv > 0) ? (!isHovered2 ? <img id={minus(btnId)} src="/skillBG/a2.png" alt="Minus" style={imgStyle} /> : <img id={minus(btnId)} src="/skillBG/b2.png" alt="Minus" style={imgStyle} />)
            : <img id={minus(btnId)} src="/skillBG/d2.png" alt="Minus" style={imgStyle} />}
        </div>
      </div>
    </>
  );
};

export default ImageHover;

import React from 'react';
import { Table, TableContainer, Box, LinearProgress , TableHead, TableCell, TableRow, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const EmptyTable = ({ theme }) => {
    // Create a custom styled table cell using the styled function
    const CustomTableCell = styled(TableCell)({
    borderBottom: '1px solid rgba(2,2,2,0.5)',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
        });

    const SpanTypography = styled(Typography)({
        fontSize: theme.typography.fontSize,
        cursor: theme.root.cursor,
        });


  return (
      <>
        <TableContainer component={Paper} sx={{ p: 0 }}>
            <Table>
              <TableHead >
                <TableRow sx={{ cursor: 'pointer', backgroundColor: theme.palette.table.headerbg}}>
                    <CustomTableCell sx={{width: 'auto'}}>
                        <SpanTypography variant="subtitle1" >No Results...</SpanTypography>
                    </CustomTableCell>
                </TableRow>
              </TableHead>



            </Table>
        </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
            <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
            </Box>
        
      </>

  );
}

export default EmptyTable;

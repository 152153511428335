import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import dragonaGold from '../assets/gold.png';
import dragonaSilver from '../assets/silver.png';
import dragonaCopper from '../assets/copper.png';



const QsTypography = ({ label, textStyle, q, theme }) => {

  const SpanTypography = styled(Typography)({
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
    color: theme.palette.tooltip.row,
    lineHeight: 1.4,
  });

  const magicStyle = {
    color: "#090",
  }

  const boxWrap = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0),
    padding: 0,
    paddingLeft: '7px',
  }

  const getGold = (gold) => {

    const g = String(gold)

    const goldC = g.substring(g.length - 2, g.length);
    const goldS = g.substring(g.length - 4, g.length - 2);
    const goldG = g.substring(g.length - 6, g.length - 4);

    return { g: goldG, s: goldS, c: goldC }
  }

  const gg = getGold(q.gold)


  return (
    <>

      {(label === 'level' && <SpanTypography variant="subtitle1" sx={textStyle}>{q.reqLv}</SpanTypography>)}
      {(label === 'name' && <SpanTypography variant="subtitle1" >{q.qName}</SpanTypography>)}
      {(label === 'code' && <SpanTypography variant="subtitle1" >{q.qCode}</SpanTypography>)}
      {(label === 'sNpc' && <SpanTypography variant="subtitle1" >{q.npcGet !== 0 ? q.npcGet : '-'}</SpanTypography>)}
      {(label === 'field' && <SpanTypography variant="subtitle1" >{q.fieldGet !== 0 ? q.fieldGet : '-'}</SpanTypography>)}
      {(label === 'sp' && <SpanTypography variant="subtitle1" >Skill Pt: {q.sp}</SpanTypography>)}
      {(label === 'xp' && <SpanTypography variant="subtitle1" >{q.xp}</SpanTypography>)}
      {(label === 'gold' &&
        <SpanTypography variant="subtitle1" >
          <Box sx={boxWrap}>

            {gg.g !== '' &&
              <>
                <span style={{ color: 'rgb(222, 110, 42)', textSize: '11px' }}>{gg.g}&nbsp;</span>
                <Box sx={{ position: 'relative', display: 'inline', top: '2px' }}>
                  <img src={dragonaGold} alt='G' />
                </Box>
                &nbsp;
              </>
            }

            {gg.s !== '' &&
              <>
                <span style={{ color: 'rgb(190, 180, 180)', textSize: '11px' }}>{gg.s}&nbsp;</span>
                <Box sx={{ position: 'relative', display: 'inline', top: '2px' }}>
                  <img src={dragonaSilver} alt='G' />
                </Box>
                &nbsp;
              </>
            }

            <span style={{ color: 'rgb(229, 115, 115)', textSize: '11px' }}>{gg.c}&nbsp;</span>
            <Box sx={{ position: 'relative', display: 'inline', top: '2px' }}>
              <img src={dragonaCopper} alt='G' />
            </Box>
          </Box>
        </SpanTypography>)}




    </>

  );
};


export default QsTypography;



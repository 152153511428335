import React, { useState, useEffect, useRef } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow, Paper, Typography, TablePagination, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import QsPop from './QsPop';
import QsTypography from './QsTypography'



const QuestTable = ({ qs, page, setPage, theme }) => {
  const [rowsPerPage, setRowsPerPage] = useState(20); // Number of items per page, defaulting to 10
  const [orderBy, setOrderBy] = useState('qCode'); // Default sorting by item name
  const [order, setOrder] = useState('asc'); // Default sorting order

  // Create a ref to the selectable text element
  const selectableTextRef = useRef(null);

  // Use useEffect to remove focus on mount
  useEffect(() => {
    if (selectableTextRef.current) {
      selectableTextRef.current.blur();
    }
  }, []); // Empty dependency array ensures the effect runs only once on mount



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
    setPage(0);
  };


  const sortedQs = qs.slice().sort((a, b) => {
    const isAsc = order === 'asc';
    if (a[orderBy] < b[orderBy]) {
      return isAsc ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return isAsc ? 1 : -1;
    }
    return 0;
  });


  // Create a custom styled table cell using the styled function
  const CustomTableCell = styled(TableCell)({
    borderBottom: '1px solid rgba(2,2,2,0.5)',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
    padding: '7px 10px',
  });

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      color: theme.palette.tooltip.text,
    },
  }));

  const SpanTypography = styled(Typography)({
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
  });



  const DragonaTablePagination = styled(TablePagination)({
    '& .MuiTablePagination-menuItem ': {
      cursor: theme.root.cursor, // Apply custom cursor style to option items within TablePagination's select dropdown
    },
    '& .MuiTablePagination-actions button': {
      cursor: theme.root.cursor, // Apply custom cursor style (e.g., pointer) to buttons within TablePagination
    },
    '& .MuiTablePagination-select': {
      cursor: theme.root.cursor, // Apply custom cursor style to option items within TablePagination's select dropdown
    },

  });

  const getSortIcon = (property) => {
    if (orderBy === property) {
      return order === 'asc' ? <ArrowUpwardIcon sx={{ fontSize: 11 }} /> : <ArrowDownwardIcon sx={{ fontSize: 11 }} />;
    }
    return null;
  };





  const unselectable = {
    WebkitUserSelect: 'none', /* For Safari */
    MozUserSelect: 'none', /* For Firefox */
    msUserSelect: 'none', /* For Microsoft Edge and Internet Explorer */
    userSelect: 'none' /* Standard syntax */
  }

  const selectable = {
    WebkitUserSelect: 'text', /* For Safari */
    MozUserSelect: 'text', /* For Firefox */
    msUserSelect: 'text',
    userSelect: 'text' /* Standard syntax */
  }

  const labelStyle = {
    userSelect: 'none'
  }




  return (
    <>




      <TableContainer component={Paper} sx={{ ...unselectable, p: 0 }}>
        <Table >
          <TableHead >
            <TableRow sx={{ backgroundColor: theme.palette.table.headerbg, gap: theme.spacing(0) }}>
              <CustomTableCell sx={{ width: { xs: '70px', sm: '70px', md: '70px', lg: '70px' } }} onClick={() => handleSortRequest('reqLv')}>
                <SpanTypography variant="subtitle1" >ReqLv {getSortIcon('reqLv')}</SpanTypography>
              </CustomTableCell>
              <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '90px', lg: '90px' } }} onClick={() => handleSortRequest('qCode')}>
                <SpanTypography variant="subtitle1" >Code {getSortIcon('qCode')}</SpanTypography>
              </CustomTableCell>
              <CustomTableCell sx={{ width: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' } }} onClick={() => handleSortRequest('qName')}>
                <SpanTypography variant="subtitle1" >Quest Name {getSortIcon('qName')}</SpanTypography>
              </CustomTableCell>


              <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '90px', lg: '120px' } }} >
                <SpanTypography variant="subtitle1" >Start NPC</SpanTypography>
              </CustomTableCell>


              <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '90px', lg: '120px' } }} >
                <SpanTypography variant="subtitle1" >Field</SpanTypography>
              </CustomTableCell>

              <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '90px', lg: '90px' } }} >
                <SpanTypography variant="subtitle1" >Reward (SP)</SpanTypography>
              </CustomTableCell>

              <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '90px', lg: '90px' } }} >
                <SpanTypography variant="subtitle1" >Reward (XP)</SpanTypography>
              </CustomTableCell>

              <CustomTableCell sx={{ width: { xs: '100px', sm: '100px', md: '120px', lg: '130px' } }} >
                <SpanTypography variant="subtitle1" >&nbsp;&nbsp;Reward (Gold)</SpanTypography>
              </CustomTableCell>




            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? sortedQs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : sortedQs
            ).map(q => (
              <React.Fragment key={q.qCode}>

                <TableRow sx={{ gap: theme.spacing(0), }}>
                  <HtmlTooltip title={<QsPop q={q} theme={theme} />} placement="right-start" >
                    <CustomTableCell sx={{ width: { xs: '70px', sm: '70px', md: '70px', lg: '70px' } }} >
                      <QsTypography label={'level'} textStyle={labelStyle} q={q} theme={theme} />
                    </CustomTableCell>
                  </HtmlTooltip>

                  <HtmlTooltip title={<QsPop q={q} theme={theme} />} placement="right-start" >
                    <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '90px', lg: '90px' } }} >
                      <QsTypography label={'code'} textStyle={labelStyle} q={q} theme={theme} />
                    </CustomTableCell>
                  </HtmlTooltip>

                  <CustomTableCell sx={{ width: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' } }} >
                    <QsTypography label={'name'} textStyle={labelStyle} q={q} theme={theme} />
                  </CustomTableCell>

                  <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '90px', lg: '120px' } }} >
                    <QsTypography label={'sNpc'} textStyle={labelStyle} q={q} theme={theme} />
                  </CustomTableCell>

                  <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '90px', lg: '120px' } }} >
                    <QsTypography label={'field'} textStyle={labelStyle} q={q} theme={theme} />
                  </CustomTableCell>

                  <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '90px', lg: '90px' } }} >
                    <QsTypography label={'sp'} textStyle={labelStyle} q={q} theme={theme} />
                  </CustomTableCell>

                  <CustomTableCell sx={{ width: { xs: '80px', sm: '80px', md: '90px', lg: '90px' } }} >
                    <QsTypography label={'xp'} textStyle={labelStyle} q={q} theme={theme} />
                  </CustomTableCell>

                  <CustomTableCell sx={{ width: { xs: '100px', sm: '100px', md: '120px', lg: '130px' } }} >
                    <QsTypography label={'gold'} textStyle={labelStyle} q={q} theme={theme} />
                  </CustomTableCell>




                </TableRow>

              </React.Fragment>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
      <DragonaTablePagination
        rowsPerPageOptions={[8, 10, 20, 30, 50]} // Options for items per page
        component="div"
        count={qs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}

      />


    </>

  );
}

export default QuestTable;

import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const MobTypography = ({ label, textStyle, item, theme, statIndex, textValue }) => {

  const SpanTypography = styled(Typography)({
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
    color: theme.palette.tooltip.row,
    lineHeight: 1.4,
  });

  const magicStyle = {
    color: "#090",
  }



  return (
    <>

          {(label === 'level' && <SpanTypography variant="subtitle1" sx={textStyle}>{item.lvl}</SpanTypography>)}
          {(label === 'name' && <SpanTypography variant="subtitle1" >{item.name}</SpanTypography>)}
          {(label === 'job' && <SpanTypography variant="subtitle1" >{item.job}</SpanTypography>)}
          {(label === 'map' && <SpanTypography variant="subtitle1" > </SpanTypography>)}
          {(label === 'hp' && <SpanTypography variant="subtitle1" sx={magicStyle}>{item.hp}</SpanTypography>)}
          {(label === 'mp' && <SpanTypography variant="subtitle1" sx={magicStyle}>{item.mp}</SpanTypography>)}
          {(label === 'xp' && <SpanTypography variant="subtitle1" sx={magicStyle}>{item.xp}</SpanTypography>)}
          {(label === 'code' && <SpanTypography variant="subtitle1" >{item.code}</SpanTypography>)}

    </>

  );
};


export default MobTypography;



import React, { useState, useEffect } from 'react';
import { Container, Box, TextField } from '@mui/material';
import MobTable from './components/MobTable';
import EmptyTable from './components/EmptyTable';

import jsonItem from './json/allMobsv1.json';


const MobPage = ({ theme }) => {
  const [filteredItems, setFilteredItems] = useState([]);
  const [page, setPage] = useState(0);

  const [searchFilter, setSearchFilter] = useState('');

  const fetchItems = async () => {
    try {

      const modifiedResponse2 = jsonItem.filter(item => (item.lvl !== 0 && item.lvl !== undefined));

      // originalResponse.data.map
      const modifiedResponse = modifiedResponse2.map(item => {
        const mobLv = parseInt(item.lvl)
        const mobHp = parseInt(item.hp)


        return { ...item, lvl: mobLv, mobLv, mobHp };


      });

      setFilteredItems(modifiedResponse);
    } catch (error) {
      console.error(error);
    }
  };


  const handleSearch = (event) => {
    setPage(0);

    // Remove non-alphanumeric characters using regular expression
    const stringWithoutSpecialChars = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

    setSearchFilter(stringWithoutSpecialChars)


    // load all unfiltered items
    let scoutItems = filteredItems

    try {
      const wordToCheck = stringWithoutSpecialChars;
      const regex = new RegExp(wordToCheck, 'i'); // 'i' flag for case-insensitive matching
      scoutItems = scoutItems.filter(obj => regex.test(obj.name));

    } catch (error) {
      console.log(error)
    }

    setFilteredItems(scoutItems);
  }


  useEffect(() => {
    fetchItems();
  }, []);



  return (
    <Container sx={{ pt: { xs: '5px', md: '20px' }, px: 0, cursor: theme.root.cursor, }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <div sx={{ width: '400px' }} >
          <TextField id="outlined-search" label="Search field" value={searchFilter} type="search" onChange={handleSearch} />
        </div>
      </Box>
      <Box sx={{ py: 1, cursor: theme.root.cursor, }}>
        {(
          (filteredItems.length > 0 ? <MobTable items={filteredItems} theme={theme} page={page} setPage={setPage} /> : <EmptyTable theme={theme} />)
        )}

      </Box>
    </Container>
  );
};

export default MobPage;

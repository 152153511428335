import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const EquipTypography = ({ label, textStyle, item, theme, itemKey, textSection }) => {

  const SpanTypography = styled(Typography)({
    fontSize: theme.typography.fontSize,
    cursor: theme.root.cursor,
    color: theme.palette.tooltip.text,
    lineHeight: 1.5,
  });


  const dLabels = [
    'Item Type', // 0
    'Rarity', // 1
    'EquipClass', // 2
    'Required Lv.', // 3
    'Cooldown', // 4
    'Attack', // 5
    'Attack', // 6
    'Movement Speed', // 7
    'Max HP',         // 8
    'Max MP',         // 9
    'Max CORE',       // 10
    'Max HP',         // 11          with by - 11, 12, 13, 20, 21, 23-33
    'Max MP',         // 12 
    'Max CORE',       // 13 
    'HP Recovery not in Combat',     // 14 
    'MP Recovery not in Combat',     // 15
    'CORE Recovery not in Combat',   // 16
    'HP Recovery in Combat',         // 17
    'MP Recovery in Combat',         // 18
    'CORE Recovery in Combat',       // 19
    'Min Damage',     // 20 
    'Max Damage',     // 21
    'Critical Hit',   // 22 
    'Defense',        // 23
    'Protection',     // 24
    'Magic Def',        // 25
    'Evasion',      // 26
    'Fire Res',       // 27 
    'Lightning Res',  // 28 
    'Cold Res',       // 29 
    'Poison Res',     // 30 
    'Dark Res',       // 31 
    'Elemental Attack', // 32 
    'Elemental Attack', // 33
    'Projectile Speed', // 34
  ]

  // jobClass = item.dValue[]
  const eLabels = {
    16: 'CORE consumption out of combat',
    19: 'CORE consumption during combat',
  }

  // jobClass = item.dValue[2]
  const jobClass = {
    100: '',
    101: 'Gunner',
    102: 'Sniper',
    103: 'Ranger',
    104: 'Magician',
    105: 'Guardian',
    106: 'Assassin',
    107: 'Shaman',
    108: 'Scout',
    109: '',
  }

  // itemType = item.dValue[0]
  const itemType = {
    103: 'Helm',
    102: 'Arm Transformation',
    105: 'Upper Body',
    106: 'Weapon',
    107: 'Gloves',
    108: 'Lower Body',
    109: 'Shoes',
    111: 'Necklace',
    112: 'Talisman',
    113: 'Ring',
    119: 'Head Transformation',
    120: 'Upper Body Transformation',
    121: 'Arm Transformation',
    122: 'Lower Body Transformation',
    124: 'Full Transformation',
    125: 'Unique Transformation',
    140: 'Sealed Item',
    145: 'Pandora',
  }

  // itemNameStyles = item.dValue[1]
  const itemNameStyles = {
    1: { color: theme.palette.text.primary },
    2: { color: theme.palette.rarity.magic },
    3: { color: theme.palette.rarity.rare },
    4: { color: theme.palette.rarity.unique },
    5: { color: theme.palette.rarity.legend },
    default: { color: theme.palette.tooltip.default },
  };

  // statLabel
  // 1 = dLabels
  // 2 = eLabels
  // 3 = jobClass
  // 4 = itemType
  // 5 = stat value

  const labelStyle = {
    minWidth: '150px',
    paddingLeft: '12pt',
    color: theme.palette.tooltip.label,

    fontWeight: '300',
  }

  const statGenStyle = {
    lineHeight: 1.3,
  }


  return (
    <>


      {((label === 'code') && (textStyle !== undefined) &&
        <SpanTypography sx={textStyle} variant="subtitle1">{item.code}</SpanTypography>
      )}
      {((label === 'code') && (textStyle === undefined) &&
        <SpanTypography sx={{ color: theme.palette.text.primary }} variant="subtitle1">{item.code}</SpanTypography>
      )}


      {((label === 'itemType') && (textStyle !== undefined) &&
        <SpanTypography sx={textStyle} variant="subtitle1">{itemType[item.dValue[0]]}</SpanTypography>
      )}

      {((label === 'itemType') && (textStyle === undefined) &&
        <SpanTypography sx={{ color: theme.palette.text.primary }} variant="subtitle1">{itemType[item.dValue[0]]}</SpanTypography>
      )}



      {((label === 'jobClass') && (textStyle !== undefined) &&
        <SpanTypography sx={textStyle} variant="subtitle1">{jobClass[item.dValue[2]]}</SpanTypography>
      )}
      {((label === 'jobClass') && (textStyle === undefined) &&
        <SpanTypography sx={{ color: theme.palette.text.primary }} variant="subtitle1">{jobClass[item.dValue[2]]}</SpanTypography>
      )}


      {((label === 'level') && (textStyle !== undefined) &&
        <SpanTypography sx={textStyle} variant="subtitle1">{item.dValue[3] - 1000}</SpanTypography>
      )}
      {((label === 'level') && (textStyle === undefined) &&
        <SpanTypography sx={{ color: theme.palette.text.primary }} variant="subtitle1">{item.dValue[3] - 1000}</SpanTypography>
      )}


      {((label === 'noLabel') &&
        <SpanTypography sx={textStyle} variant="subtitle1">{item}</SpanTypography>
      )}



      {((label === 'itemNameStyles') && (
        <SpanTypography
          sx={(((theme.palette.mode === 'light') && (item.dValue[1] === 1)) ?
            ({ color: theme.palette.tooltip.label })
            :
            (itemNameStyles[item.dValue[1]])
          )}

          style={(((theme.palette.mode === 'light') && (textSection === 'tableRow')) ?
            (itemNameStyles[item.dValue[1]])
            :
            null
          )}
          variant="subtitle1"
        >
          {item.name}
        </SpanTypography>
      ))}


      {((label === 'dLabels') && (
        <>
          <SpanTypography sx={labelStyle} style={statGenStyle}>{dLabels[itemKey]}</SpanTypography>
          <SpanTypography sx={itemNameStyles[2]} style={{ ...statGenStyle, paddingLeft: '5px' }}>
            {([7, 8, 9, 10, 14, 15, 17, 18, 23, 33].includes(Number(itemKey))) ? (
              item.dValue[itemKey] + ` Increased `
            ) : (
              item.dValue[itemKey] + ` Increased by`
            )}
          </SpanTypography>
        </>
      ))}

      {((label === 'attack') && (
        <>
          <SpanTypography sx={labelStyle} style={statGenStyle}>Attack</SpanTypography>
          <SpanTypography sx={itemNameStyles['default']} style={{ ...statGenStyle, paddingLeft: '5px' }}>{item.dValue[5]} ~ {item.dValue[6]}</SpanTypography>
        </>
      ))}

      {((label === 'eLabels') && (
        <>
          <SpanTypography sx={labelStyle} style={{ ...statGenStyle, paddingTop: '4px' }}>{eLabels[itemKey]}</SpanTypography>
          <SpanTypography sx={itemNameStyles[2]} style={{ ...statGenStyle, paddingLeft: '4px', paddingTop: '4px' }}>{(item.dValue[itemKey] - 80)}</SpanTypography>
        </>
      ))}

      {((label === 'text') && (
        <SpanTypography sx={{ pl: '6px', pr: '5px' }}>
          {Object.keys(item.textArray).map((key, index) => (
            item.textArray[key] !== "" && (
              <React.Fragment key={index}>
                {item.textArray[key]}<br />
              </React.Fragment>
            )))}
        </SpanTypography>
      ))}

    </>

  );
};


export default EquipTypography;



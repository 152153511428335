import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import SkillPop from './SkillPop';
import Draggable from 'react-draggable';

import SkillImage from './SkillImage';
import allSkills from "../json/allSkillsv4.json"
import ImageHover from './ImageHover';
import JobHover from './JobHover';






const SkillTable = ({ skills, theme }) => {
  // useStates
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [charLv, setCharLv] = useState(40);
  const [skillPts, setSkillPts] = useState(83);
  const [allDisabled, setAllDisabled] = useState(0);
  const [activeIdx, setActiveIdx] = useState(0);
  const [aorP, setAorP] = useState(1);
  const [selectedJobNum, setSelectedJobNum] = useState(1);

  const [sks, setSks] = useState({
    1: { code: 201000001, ic: 1, slotIdx: 1, curLv: 0, maxLv: 1, plusDisabled: 1 },
    7: { code: 201000002, ic: 2, slotIdx: 7, curLv: 0, maxLv: 20, plusDisabled: 0 },
  })

  const [skP, setSkP] = useState({
    1: { code: 201000001, ic: 1, slotIdx: 1, curLv: 0, maxLv: 1, plusDisabled: 1 },
    7: { code: 201000002, ic: 2, slotIdx: 7, curLv: 0, maxLv: 20, plusDisabled: 0 },
  })

  const getSkillsObj = (type) => {
    // type = active/passive
    const filteredData = allSkills.filter(obj => (obj.type === type && obj.activeSkillUsePc === selectedJobNum));


    // Get the object with the lowest code for each slotIndex
    const result = filteredData.reduce((acc, curr) => {
      if (!acc[curr.slotIndex] || curr.code < acc[curr.slotIndex].code) {
        acc[curr.slotIndex] = curr;
      }
      return acc;
    }, {}); // Initialize accumulator as an empty object

    return result;
  }


  const mainImgContainer = {
    position: 'relative',
    width: '39px',
    height: '57px',
    margin: '0 6px',
    gap: theme.spacing(0)
  }

  const boxWrap = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0),
    paddingBottom: '8px',
  }

  const subWrap = {
    pt: '6px',
  }

  const styles = {
    container: {
      backgroundColor: 'transparent',
      padding: '0px',
      position: 'absolute',
      zIndex: 900,
      transform: 'translate(-50%, -50%)',
      color: theme.palette.tooltip.text,
      cursor: theme.root.handCursor
    },
    closeButton: {
      position: 'absolute',
      top: '9px',
      right: '10px',
      cursor: theme.root.cursor
    },
  };

  const skNumCtr = {
    pt: '1px',
    ml: '-4px',
    textAlign: 'center',
  }
  const skNumText = {
    fontFamily: 'calibri',
    fontSize: theme.typography.fontSize,
    fontWeight: 300,
    color: theme.palette.rarity.other
  }
  const skCurNum = {
    color: theme.palette.rarity.green
  }
  const skCurNumRed = {
    color: theme.palette.rarity.legend
  }




  const findSkByCode = (skJson, code) => {
    const filtered = skJson.filter(sk => sk.code === code)
    return filtered[0]
  }


  const getAllActiveSkills = () => {
    const skObj = getSkillsObj(2);
    let newObj = {};

    Object.entries(skObj).forEach(([key, value]) => {
      if (!isNaN(key)) {
        newObj = {
          ...newObj,
          [key]: { code: value.code, ic: value.ic, slotIdx: key, curLv: 0, maxLv: value.maxLv, plusDisabled: 0 },
        };
      }
    });

    return newObj;
  }

  const getAllPassiveSkills = () => {
    const skPObj = getSkillsObj(1);
    let newPObj = {};

    Object.entries(skPObj).forEach(([key, value]) => {
      if (!isNaN(key)) {
        newPObj = {
          ...newPObj,
          [key]: { code: value.code, ic: value.ic, slotIdx: key, curLv: 0, maxLv: value.maxLv, plusDisabled: 0 },
        };
      }
    });

    return newPObj;
  }

  useEffect(() => {
    const newAct = getAllActiveSkills();
    const newPass = getAllPassiveSkills();



    setSks({ ...newAct });
    setSkP({ ...newPass });

  }, [selectedJobNum]); // Empty dependency array means this effect runs only once, similar to componentDidMount


  useEffect(() => {

    if (skillPts <= 0) {
      setAllDisabled(1)
    } else {
      setAllDisabled(0)
    }

  }, [skillPts]);

  //handle + - buttons // Active
  const handlePlus = (e) => {
    const btnId = e.target.id;
    let idx = 0;

    // get btn index
    if (btnId > 1000 && btnId < 2000) {
      idx = btnId - 1000
    } else if (btnId > 2000) {
      idx = btnId - 2000
    }

    try {
      const skObj = findSkByCode(allSkills, sks[idx].code);
      const nextSkObj = findSkByCode(allSkills, sks[idx].code + 1);
      const nextNextSkObj = findSkByCode(allSkills, sks[idx].code + 2);
      let checkPlus = 0

      if ((sks[idx].curLv + 1 >= skObj.maxLv) || (nextNextSkObj.reqLv > charLv) || (skillPts - 1 <= 0)) {
        checkPlus = 1
        if (sks[idx].curLv === 0) {
          if (nextSkObj.reqLv > charLv) {
            checkPlus = 1
          } else {
            checkPlus = 0
          }
        }
      }

      if (btnId > 2000) {
        // minus btn
        if (sks[idx].curLv !== 0) {
          setSkillPts(skillPts + 1);
          if (sks[idx].curLv === 1) {
            setSks({
              ...sks,
              [idx]: { ...sks[idx], curLv: sks[idx].curLv - 1, plusDisabled: 0 },
            });
          }
          else {
            setSks({
              ...sks,
              [idx]: { ...sks[idx], code: sks[idx].code - 1, curLv: sks[idx].curLv - 1, plusDisabled: 0 },
            });
          }
        }
      } else if (btnId > 1000 && btnId < 2000) {
        // plus btn
        if (sks[idx].plusDisabled === 0 && skillPts > 0) {
          setSkillPts(skillPts - 1);
          if (sks[idx].curLv === 0) {
            setSks({
              ...sks,
              [idx]: { ...sks[idx], curLv: sks[idx].curLv + 1, plusDisabled: checkPlus },
            });
          } else {
            setSks({
              ...sks,
              [idx]: { ...sks[idx], code: sks[idx].code + 1, curLv: sks[idx].curLv + 1, plusDisabled: checkPlus },
            });
          }
        }
      }

    } catch (error) {

    }
  };


  //handle + - buttons // Passive
  const handlePlus2 = (e) => {
    const btnId = e.target.id;
    let idx = 0;

    // get btn index
    if (btnId > 1000 && btnId < 2000) {
      idx = btnId - 1000
    } else if (btnId > 2000) {
      idx = btnId - 2000
    }

    try {
      const skObj = findSkByCode(allSkills, skP[idx].code);
      const nextSkObj = findSkByCode(allSkills, skP[idx].code + 1);
      const nextNextSkObj = findSkByCode(allSkills, skP[idx].code + 2);
      let checkPlus = 0

      if ((skP[idx].curLv + 1 >= skObj.maxLv) || (nextNextSkObj.reqLv > charLv) || (skillPts - 1 <= 0)) {
        checkPlus = 1
        if (skP[idx].curLv === 0) {
          if (nextSkObj.reqLv > charLv) {
            checkPlus = 1
          } else {
            checkPlus = 0
          }
        }
      }

      if (btnId > 2000) {
        // minus btn
        if (skP[idx].curLv !== 0) {
          setSkillPts(skillPts + 1);
          if (skP[idx].curLv === 1) {
            setSkP({
              ...skP,
              [idx]: { ...skP[idx], curLv: skP[idx].curLv - 1, plusDisabled: 0 },
            });
          }
          else {
            setSkP({
              ...skP,
              [idx]: { ...skP[idx], code: skP[idx].code - 1, curLv: skP[idx].curLv - 1, plusDisabled: 0 },
            });
          }
        }
      } else if (btnId > 1000 && btnId < 2000) {
        // plus btn
        if (skP[idx].plusDisabled === 0 && skillPts > 0) {
          setSkillPts(skillPts - 1);
          if (skP[idx].curLv === 0) {
            setSkP({
              ...skP,
              [idx]: { ...skP[idx], curLv: skP[idx].curLv + 1, plusDisabled: checkPlus },
            });
          } else {
            setSkP({
              ...skP,
              [idx]: { ...skP[idx], code: skP[idx].code + 1, curLv: skP[idx].curLv + 1, plusDisabled: checkPlus },
            });
          }
        }
      }

    } catch (error) {

    }
  };




  const handleMouseEnter = (e, idx, ap) => {

    setActiveIdx(idx);
    setAorP(ap);
    let yy = e.clientY

    setPosition({ x: e.clientX + 35, y: yy });
    setIsVisible(true);
  }

  const handleMouseLeave = (e) => setIsVisible(false);


  const handleJobClick = (jobClickNum) => {
    const newAct = getAllActiveSkills();
    const newPass = getAllPassiveSkills();

    setSks({ ...newAct });
    setSkP({ ...newPass });
    setSkillPts(83);

    const jn = Number(jobClickNum.target.id)
    // set the selected Job for the skill sim
    if (isNaN(jn)) {
      setSelectedJobNum(1)
    } else {
      setSelectedJobNum(jn)
    }
  }



  return (
    <>
      {(isVisible && aorP === 1) && (
        <Draggable defaultPosition={{ x: 0, y: 0 }} >
          <Box style={{ ...styles.container, left: position.x, top: position.y }}>
            <SkillPop skCode={sks[activeIdx].code} theme={theme} />
            {(sks[activeIdx].curLv > 0) && (sks[activeIdx].curLv < sks[activeIdx].maxLv) && <SkillPop skCode={sks[activeIdx].code + 1} theme={theme} />}
          </Box>
        </Draggable>
      )}
      {(isVisible && aorP === 2) && (
        <Draggable defaultPosition={{ x: 0, y: 0 }} >
          <Box style={{ ...styles.container, left: position.x, top: position.y }}>
            <SkillPop skCode={skP[activeIdx].code} theme={theme} />
            {(skP[activeIdx].curLv > 0) && (skP[activeIdx].curLv < skP[activeIdx].maxLv) && <SkillPop skCode={skP[activeIdx].code + 1} theme={theme} />}
          </Box>
        </Draggable>
      )}

      <Box sx={{ ...boxWrap, justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
        <JobHover jobNum={1} isSelected={selectedJobNum === 1 ? 1 : 0} handleJobClick={handleJobClick} />
        <JobHover jobNum={2} isSelected={selectedJobNum === 2 ? 1 : 0} handleJobClick={handleJobClick} />
        <JobHover jobNum={4} isSelected={selectedJobNum === 4 ? 1 : 0} handleJobClick={handleJobClick} />
        <JobHover jobNum={8} isSelected={selectedJobNum === 8 ? 1 : 0} handleJobClick={handleJobClick} />
        <JobHover jobNum={16} isSelected={selectedJobNum === 16 ? 1 : 0} handleJobClick={handleJobClick} />
        <JobHover jobNum={32} isSelected={selectedJobNum === 32 ? 1 : 0} handleJobClick={handleJobClick} />
        <JobHover jobNum={64} isSelected={selectedJobNum === 64 ? 1 : 0} handleJobClick={handleJobClick} />
        <JobHover jobNum={128} isSelected={selectedJobNum === 128 ? 1 : 0} handleJobClick={handleJobClick} />

        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography sx={{ color: theme.palette.rarity.unique, fontWeight: '300' }}>Char Lv: {charLv} | Skill Points: {skillPts}</Typography>
        </Box>
      </Box>

      <Box sx={{ ...boxWrap, width: '100%', pt: '10px' }}>
        {/* ACTIVE SKILLS */}
        <Box sx={{ width: { sx: '100%', md: '50%' }, pl: { md: '14%' } }}>


          <Box sx={{ ...boxWrap, ...subWrap }}><Box sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, 1, 1)} onMouseLeave={(e) => handleMouseLeave(e)}>
            <SkillImage skCode={sks[1].code} theme={theme} />
            <Box onClick={(e) => handlePlus(e)} ><ImageHover btnId={sks[1].slotIdx} skCurLv={0} plusDisabled={1} /></Box>
            <Box sx={skNumCtr}><Typography sx={skNumText}>1/1</Typography></Box>
          </Box></Box>

          {/* Rendering for keys 7 to 10 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(sks)
              .filter(([key]) => [7, 8, 9, 10, 11].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 1)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} />
                    <Box onClick={(e) => handlePlus(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>


          {/* Rendering for keys 13 to 17 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(sks)
              .filter(([key]) => [13, 14, 15, 16, 17].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 1)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} />
                    <Box onClick={(e) => handlePlus(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

          {/* Rendering for keys 19 to 23 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(sks)
              .filter(([key]) => [19, 20, 21, 22, 23].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 1)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} />
                    <Box onClick={(e) => handlePlus(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

          {/* Rendering for keys 25 to 29 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(sks)
              .filter(([key]) => [25, 26, 27, 28, 29].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 1)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} />
                    <Box onClick={(e) => handlePlus(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

          {/* Rendering for keys 31 to 35 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(sks)
              .filter(([key]) => [31, 32, 33, 34, 35].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 1)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} />
                    <Box onClick={(e) => handlePlus(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

          {/* Rendering for keys 37 to 40 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(sks)
              .filter(([key]) => [37, 38, 39, 40].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 1)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} />
                    <Box onClick={(e) => handlePlus(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

          {/* Rendering for keys 43 to 45 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(sks)
              .filter(([key]) => [43, 44, 45].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 1)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} />
                    <Box onClick={(e) => handlePlus(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>


          {/* Rendering for keys 49 to 50 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(sks)
              .filter(([key]) => [49, 50].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 1)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} />
                    <Box onClick={(e) => handlePlus(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

          {/* Rendering for keys 54 to 56 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(sks)
              .filter(([key]) => [54, 55, 56].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 1)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} />
                    <Box onClick={(e) => handlePlus(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

        </Box>

        {/* PASSIVE SKILLS */}
        <Box sx={{ width: { sx: '100%', md: '50%' }, pl: { md: '14%' } }}>

          {/* Rendering for keys 54 to 56 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(skP)
              .filter(([key]) => [1, 2, 3].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key + 3000} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 2)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} ap={1} />
                    <Box onClick={(e) => handlePlus2(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

          {/* Rendering for keys 7 to 10 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(skP)
              .filter(([key]) => [7, 8, 9, 10].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key + 3000} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 2)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} ap={1} />
                    <Box onClick={(e) => handlePlus2(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

          {/* Rendering for keys 13 to 15 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(skP)
              .filter(([key]) => [13, 14, 15].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key + 3000} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 2)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} ap={1} />
                    <Box onClick={(e) => handlePlus2(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

          {/* Rendering for keys 19 to 23 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(skP)
              .filter(([key]) => [19, 20, 21, 22, 23].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key + 3000} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 2)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} ap={1} />
                    <Box onClick={(e) => handlePlus2(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

          {/* Rendering for keys 25 */}
          <Box sx={{ ...boxWrap, ...subWrap }}>
            {Object.entries(skP)
              .filter(([key]) => [25].includes(Number(key)))
              .map(([key, value]) => (
                (value !== undefined &&
                  <Box key={key + 3000} sx={mainImgContainer} onMouseEnter={(e) => handleMouseEnter(e, key, 2)} onMouseLeave={(e) => handleMouseLeave(e)}>
                    <SkillImage skCode={value.code} theme={theme} ap={1} />
                    <Box onClick={(e) => handlePlus2(e)} ><ImageHover btnId={value.slotIdx} skCurLv={value.curLv} plusDisabled={value.plusDisabled} allDisabled={allDisabled} /></Box>
                    <Box sx={skNumCtr}><Typography sx={skNumText}><span style={(value.plusDisabled === 1 || allDisabled ? skCurNumRed : skCurNum)}>{value.curLv}</span>/{value.maxLv}</Typography></Box>
                  </Box>)
              ))}
          </Box>

        </Box>
      </Box>

    </>

  );
}

export default SkillTable;
